var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 m-auto sm:max-w-sm",attrs:{"id":"change-password"}},[_c('TheTitle',{attrs:{"title":'Passwort ändern'}}),_c('FormulateForm',{staticClass:"bg-gray-50 rounded px-5 pt-5 pb-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"name":"Email","type":"email","placeholder":"E-Mail|email","validation":"required"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('FormulateInput',{attrs:{"type":"password","name":"Aktuelles Passwort","placeholder":"Aktuelles Passwort","validation":"required"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('FormulateInput',{attrs:{"type":"password","name":"NeuesPasswort","placeholder":"Neues Passwort","validation":[
        ['required'],
        ['min', 8],
        ['matches', /[0-9]/],
        ['matches', /[A-Z]/],
        ['matches', /[$&+,:;=?@#|/§'<>.^*()%!-]/]
      ],"validation-name":"Neues Passwort","validation-messages":{
        matches:
          'Passwort muss eine Zahl, einen Großbuchstaben und ein Sonderzeichen enthalten'
      }},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('FormulateInput',{attrs:{"name":"Neues Passwort wiederholen","type":"password","placeholder":"Neues Passwort wiederholen","validation":"required|confirm:NeuesPasswort","validation-messages":{
        confirm: 'Passwörter stimmen nicht überein.'
      }},model:{value:(_vm.repeatNewPassword),callback:function ($$v) {_vm.repeatNewPassword=$$v},expression:"repeatNewPassword"}}),_c('FormulateInput',{attrs:{"type":"button","disabled":hasErrors,"value":"Passwort ändern"},on:{"click":function($event){$event.preventDefault();return _vm.changePassword($event)}}})]}}])}),_c('div',{staticClass:"text-center mt-5 text-sm"},[_c('router-link',{staticClass:"hover:underline",attrs:{"to":"/userprofile"}},[_vm._v("zurück zur Profilseite")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }