<template>
  <div
    id="school-data"
    class="flex flex-col m-0 m-auto justify-center w-full mb-10 sm:w-1/2"
    v-if="statistics"
  >
    <div class="font-bold text-xs text-center">Schulform</div>
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        legend: { position: "none" }
      }
    };
  },
  methods: {
    ...mapActions(["bindStatistics"])
  },
  mounted() {
    this.bindStatistics();
  },
  computed: {
    ...mapState(["statistics"]),
    statisticsAsObject() {
      const arrayToObject = array =>
        array.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      if (this.statistics) {
        return arrayToObject(this.statistics);
      }
      return null;
    },
    chartData() {
      let genderArray = [
        ["Schule", "Anzahl", { role: "style" }],
        ["Gymnasium", this.statisticsAsObject.school.Gymnasium, "#61B231"],
        [
          "Gesamtschule",
          this.statisticsAsObject.school.Gesamtschule,
          "#61B231"
        ],
        ["Realschule", this.statisticsAsObject.school.Realschule, "#61B231"],
        ["Hauptschule", this.statisticsAsObject.school.Hauptschule, "#61B231"],
        ["Grundschule", this.statisticsAsObject.school.Grundschule, "#61B231"],
        ["Sonstige", this.statisticsAsObject.school.Sonstige, "#61B231"]
      ];
      return genderArray;
    }
  }
};
</script>

<style></style>
