<template>
  <div
    id="sign-up"
    class="m-0 m-auto flex flex-col content-center overflow-hidden sm:w-3/5 md:w-1/2 lg:w-2/5"
  >
    <div class="mb-10">
      <span class="italic">Hinweis an Erziehungsberechtigten:</span> Falls Sie
      ihr Kind anmelden möchten, so wählen Sie bitte den Nutzertyp "Schüler*in"
      aus.
    </div>
    <FormulateForm
      #default="{hasErrors}"
      class="bg-gray-50 rounded px-5 pt-5 pb-1 lg:w-5/6"
    >
      Bitte wähle bzw. fülle alle die mit * gekennzeichneten Felder aus.
      <br />
      <br />
      <FormulateInput
        v-model="userType"
        name="Nutzertyp*"
        type="select"
        :options="{
          'Schüler*in': 'Schüler*in',
          'Lehrer*in': 'Lehrer*in',
          'Erziehungsberechtige*r': 'Erziehungsberechtige*r',
          'Professor*in': 'Professor*in',
          'Wiss. Mitarbeiter*in': 'Wiss. Mitarbeiter*in',
          'Hobby-Mathematiker*in': 'Hobby-Mathematiker*in'
        }"
        placeholder="Nutzertyp*"
        validation="required"
      />
      <FormulateInput
        v-if="userType === 'Schüler*in'"
        v-model="gender"
        name="Geschlecht"
        type="select"
        :options="{
          female: 'weiblich',
          male: 'männlich',
          diverse: 'divers'
        }"
        placeholder="Geschlecht*"
        validation="required"
        validation-behavior="live"
      />
      <FormulateInput
        v-if="userType === 'Schüler*in'"
        v-model="school"
        name="Schulform"
        type="select"
        :options="{
          Gesamtschule: 'Gesamtschule',
          Grundschule: 'Grundschule',
          Gymnasium: 'Gymnasium',
          Hauptschule: 'Hauptschule',
          Realschule: 'Realschule',
          Sonstige: 'Sonstige'
        }"
        placeholder="Schulform*"
        validation="required"
        validation-behavior="live"
      />

      <FormulateInput
        v-if="userType === 'Schüler*in'"
        v-model="grade"
        name="Jahrgang"
        type="select"
        :options="{
          '1': '1',
          '2': '2',
          '3': '3',
          '4': '4',
          '5': '5',
          '6': '6',
          '7': '7',
          '8': '8',
          '9': '9',
          '10': '10',
          '11+': '11+'
        }"
        placeholder="Jahrgang*"
        validation="required"
        validation-behavior="live"
      />

      <FormulateInput
        v-if="userType === 'Schüler*in'"
        v-model="federalState"
        name="Bundesland"
        type="select"
        :options="{
          BadenWuerttemberg: `Baden-Württemberg`,
          Bayern: 'Bayern',
          Berlin: 'Berlin',
          Bremen: 'Bremen',
          Brandenburg: 'Brandenburg',
          Hamburg: 'Hamburg',
          Hessen: 'Hessen',
          MecklenburgVorpommern: 'Mecklenburg-Vorpommern',
          Niedersachsen: 'Niedersachsen',
          NordrheinWestfalen: 'Nordrhein-Westfalen',
          RheinlandPfalz: 'Rheinland-Pfalz',
          Saarland: 'Saarland',
          Sachsen: 'Sachsen',
          SachsenAnhalt: 'Sachsen-Anhalt',
          SchleswigHolstein: 'Schleswig-Holstein',
          Thueringen: 'Thüringen'
        }"
        placeholder="Bundesland*"
        validation="required"
        validation-behavior="live"
      />
      <div class="flex flex-col">
        <div class="flex space-x-5 align-center">
          <FormulateInput
            input-class="border rounded px-2 focus:outline-none shadow py-2"
            help-class="text-left text-xs text-gray-600 pt-1"
            type="button"
            value="Avatar auswählen"
            @click="showOrCloseAvatarChoice"
            help="Optional"
          />
          <span class="py-2">{{ mathematicians[avatarName] }}</span>
          <input
            v-if="avatarIsChosen"
            type="button"
            class="cursor-pointer w-8 h-8 bg-white rounded-full flex flex-col items-center justify-center m-1 outline-none shadow"
            value="x"
            @click="deleteAvatarChoice"
          />
        </div>
        <div v-if="activeAvatarChoice">
          <div class="grid grid-cols-1 mb-10  xs:grid-cols-2">
            <div
              id="avatar-item"
              class="m-0 m-auto flex flex-col items-center text-center"
              v-for="(mathematician, portrait) in mathematicians"
              :key="portrait"
            >
              <input
                type="button"
                @click.prevent="defineAvatarName(portrait, mathematician)"
                :class="
                  `w-20 h-20 border-0 rounded-full m-4 bg-cover bg-white bg-${portrait} flex flex-col items-center align-center justify-center text-4xl cursor-pointer shadow`
                "
              />
              <div id="avatar-name" class="bg-white px-1 rounded mb-7">
                {{ mathematician }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormulateInput
        v-model="nickname"
        name="Nickname"
        type="text"
        placeholder="Nickname*"
        help="Verwende keinen Nicknamen, worin dein echter Vor- und Nachname zu erkennen ist. Dein Nickname darf nicht mit einem Punkt (.) anfangen und enden."
        validation="required|min:3|max:23"
        :errors="[
          isTaken ? `Nickname '${nickname}' ist schon vergeben.` : null,
          hasSpaces ? 'Nickname darf keine Leerzeichen enthalten.' : null,
          startsEndsWithDot
            ? 'Nickname darf nicht mit einem Punkt anfangen oder enden.'
            : null
        ]"
      />
      <FormulateInput
        v-model="email"
        type="email"
        name="E-Mail"
        placeholder="E-Mail*"
        validation="required|email"
      />

      <FormulateInput
        v-model="password"
        type="password"
        name="Passwort"
        placeholder="Passwort*"
        help="Mindestens 8 Zeichen; davon eine Zahl, ein Großbuchstabe und ein Sonderzeichen."
        :validation="[
          ['required'],
          ['min', 8],
          ['matches', /[0-9]/],
          ['matches', /[A-Z]/],
          ['matches', /[$&+,:;=?@#|/§'<>.^*()%!-]/]
        ]"
        validation-name="Passwort"
        :validation-messages="{
          matches:
            'Passwort muss eine Zahl, einen Großbuchstaben und ein Sonderzeichen enthalten'
        }"
      />

      <FormulateInput
        v-model="repeatPassword"
        name="Passwort wiederholen"
        type="password"
        placeholder="Passwort wiederholen*"
        validation="required|confirm:Passwort"
        :validation-messages="{
          confirm: 'Passwörter stimmen nicht überein.'
        }"
      />
      <FormulateInput
        type="checkbox"
        v-model="privacyPolicyAccepted"
        label="Ich habe die Datenschutzerklärung gelesen und akzeptiere sie."
        validation="required"
      />
      <FormulateInput
        type="button"
        @click.prevent="signUp"
        :disabled="hasErrors || hasSpaces || isTaken || startsEndsWithDot"
        value="Registrieren"
      />
    </FormulateForm>
    <div class="text-center p-5">
      <span style="font-size: 10pt;">
        Du bist schon registriert?
      </span>
      <router-link
        to="/signin"
        class="text-sm text-pangea-green font-semibold hover:underline"
        >Anmelden</router-link
      >
    </div>
    <div
      v-show="activeAvatarChoice && false"
      id="choose-avatar"
      class="fixed overflow-scroll z-40 top-0 left-0 right-0 bottom-0 w-full min-h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center  sm:p-0"
    >
      <div
        class="relative w-2/3 rounded flex flex-col text-center text-lg mb-96 -mb-96 sm:w-2/3 sm:my-0"
      >
        <input
          type="button"
          class="absolute top-0 right-0 cursor-pointer w-8 h-8 bg-white rounded-full flex flex-col items-center justify-center m-1 outline-none shadow"
          value="x"
          @click="showOrCloseAvatarChoice"
        />
        <span class="text-xl font-black my-10 bg-white rounded p-2"
          >Wähle bitte ein Avatar aus.</span
        >
        <div
          class="grid grid-cols-1 mb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <div
            id="avatar-item"
            class="m-0 m-auto flex flex-col items-center text-center"
            v-for="(mathematician, portrait) in mathematicians"
            :key="portrait"
          >
            <input
              type="button"
              @click.prevent="defineAvatarName(portrait, mathematician)"
              :class="
                `w-20 h-20 border-0 rounded-full m-4 bg-cover bg-white bg-${portrait} flex flex-col items-center align-center justify-center text-4xl cursor-pointer shadow`
              "
            />
            <div id="avatar-name" class="bg-white px-1 rounded mb-7">
              {{ mathematician }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { auth, db } from "@/firebase.js";
import firebase from "firebase/app";

export default {
  name: "SignUp",
  data() {
    return {
      avatarIsChosen: false,
      avatarName: "",
      email: "",
      federalState: "",
      gender: "",
      grade: "",
      nameOfTheMathematician: "Avatar auswählen",
      nickname: "",
      password: "",
      privacyPolicyAccepted: false,
      repeatPassword: "",
      school: "",
      userType: "",
      activeAvatarChoice: false,
      successMessage:
        "Du hast dich erfolgreich registriert. Wir haben an deine E-Mail-Adresse ein Link zur Verifizierung deiner E-Mail-Adresse gesendet. Bitte verifiziere deine E-Mail-Adresse. Danach kannst du dich anmelden. Du wirst jetzt zur Anmeldeseite weitergeleitet.",
      mathematicians: {
        gauss: "Carl Friedrich Gauß",
        euler: "Leonhard Euler",
        germain: "Sophie Germain",
        lovelace: "Ada Lovelace",
        kowalewskaja: "Sofja Kowalewskaja",
        noether: "Emmy Noether",
        riemann: "Bernhard Riemann",
        hilbert: "David Hilbert"
      }
    };
  },
  computed: {
    ...mapState(["nicknames", "error"]),
    hasSpaces() {
      return /\s/.test(this.nickname);
    },
    startsEndsWithDot() {
      if (
        this.nickname[0] == "." ||
        this.nickname[this.nickname.length - 1] == "."
      ) {
        return true;
      } else {
        return false;
      }
    },
    isTaken() {
      if (this.nicknames) {
        let nicknameList = [];
        this.nicknames.forEach(user => {
          nicknameList.push(user.id);
        });
        if (nicknameList.includes(this.nickname)) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      "signUpAction",
      "signOutAction",
      "bindNicknames",
      "unbindNicknames",
      "errorMessageFromFirebase"
    ]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.go();
      }, 3000);
      return;
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/signin");
      }, 9000);
      return;
    },
    updateStatistics(payload) {
      let increment = firebase.firestore.FieldValue.increment(1);
      if (payload) {
        setTimeout(() => {
          db.collection("statistics")
            .doc("federalStates")
            .update({ total: increment });
          db.collection("statistics")
            .doc("gender")
            .update({ total: increment });
          db.collection("statistics")
            .doc("school")
            .update({ total: increment });
          db.collection("statistics")
            .doc("grades")
            .update({ total: increment });
          db.collection("statistics")
            .doc("federalStates")
            .update({ [`${payload.federalState}`]: increment });
          db.collection("statistics")
            .doc("gender")
            .update({ [`${payload.gender}`]: increment });
          db.collection("statistics")
            .doc("school")
            .update({ [`${payload.school}`]: increment });
          db.collection("statistics")
            .doc("grades")
            .update({ [`${payload.grade}`]: increment });
        }, 500);
      }
      return;
    },
    showOrCloseAvatarChoice() {
      this.activeAvatarChoice = !this.activeAvatarChoice;
    },
    deleteAvatarChoice() {
      this.avatarIsChosen = !this.avatarIsChosen;
      this.avatarName = "";
    },
    defineAvatarName(portrait, mathematician) {
      this.nameOfTheMathematician = mathematician;
      this.avatarName = portrait;
      this.activeAvatarChoice = false;
      this.avatarIsChosen = true;
    },
    signUp() {
      this.unbindNicknames();
      if (this.userType != "Schüler*in") {
        this.gender = "unbestimmt";
        this.school = "unbestimmt";
        this.grade = "unbestimmt";
      }
      if (!this.isTaken) {
        try {
          auth
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(() => {
              auth.currentUser.updateProfile({ displayName: this.nickname });
              db.collection("nicknames")
                .doc(this.nickname)
                .set({});
              if (this.userType === "Schüler*in") {
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .set({
                    nickname: this.nickname,
                    points: 0,
                    gender: this.gender,
                    grade: this.grade,
                    userType: this.userType,
                    federalState: this.federalState,
                    school: this.school,
                    termsAccepted: this.privacyPolicyAccepted,
                    avatar: this.avatarName
                  });
                this.updateStatistics({
                  gender: this.gender,
                  school: this.school,
                  grade: this.grade,
                  federalState: this.federalState
                });
                console.log("Statistics were updated!");
              } else {
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .set({
                    nickname: this.nickname,
                    points: 0,
                    userType: this.userType,
                    termsAccepted: this.privacyPolicyAccepted,
                    avatar: this.avatarName
                  });
              }
              auth.languageCode = "de";
              auth.currentUser
                .sendEmailVerification()
                .then(() => {
                  this.successNotification();
                  setTimeout(() => {
                    this.signOutAction();
                  }, 2000);
                })
                .catch(error => {
                  console.log(error);
                  this.errorMessageFromFirebase(error);
                  this.errorNotification();
                });
            })
            .catch(error => {
              console.log(error);
              this.errorMessageFromFirebase(error);
              this.errorNotification();
            });
        } catch (error) {
          console.log("error", error);
        }
      }
      return;
    }
  },
  mounted() {
    this.bindNicknames();
  }
};
</script>

<style>
/* #sign-up > .formulate-input {
  margin-bottom: 0.5em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

#sign-up > .formulate-input .formulate-input-label {
  display: block;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0.1em;
}

#sign-up > .formulate-input
  .formulate-input-label--before
  + .formulate-input-help--before {
  margin-top: -0.25em;
  margin-bottom: 0.75em;
}
.formulate-input .formulate-input-element {
  max-width: 20em;
  margin-bottom: 0.1em;
}

.formulate-input .formulate-input-help {
  color: #6d6d6d;
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0.25em;
}

.formulate-input .formulate-input-errors {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.formulate-input .formulate-file-upload-error,
.formulate-input .formulate-input-error {
  color: #960505;
  font-size: 0.8em;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0.25em;
}

.formulate-input:last-child {
  margin-bottom: 0;
}

.formulate-input[data-classification="text"] input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
}

.formulate-input[data-classification="text"] input::-moz-placeholder {
  color: #a8a8a8;
  line-height: normal;
}

.formulate-input[data-classification="text"] input:-ms-input-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="text"] input::placeholder {
  color: #a8a8a8;
  line-height: normal;
}

.formulate-input[data-classification="text"] input:focus {
  outline: 0;
  border: 1px solid #a5ca19;
}

.formulate-input[data-classification="text"]
  .formulate-input-element--date
  input,
.formulate-input[data-classification="text"]
  .formulate-input-element--datetime-local
  input,
.formulate-input[data-classification="text"]
  .formulate-input-element--month
  input,
.formulate-input[data-classification="text"]
  .formulate-input-element--week
  input {
  min-height: 2.2em;
}
.formulate-input[data-classification="text"] .formulate-input-element--search {
  position: relative;
}

.formulate-input[data-classification="text"]
  .formulate-input-element--search:before {
  content: "";
  width: 2em;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.99 72.9"><path fill="%236d6d6d" d="M71.77,66,53.87,48.1A29.94,29.94,0,1,0,30,60a29.52,29.52,0,0,0,18.1-6.1l18,17.8A4,4,0,0,0,69,72.9a4.18,4.18,0,0,0,2.9-1.2A4.09,4.09,0,0,0,71.77,66ZM30.07,51.9a21.9,21.9,0,1,1,15.5-37.4A21.37,21.37,0,0,1,52,30a22,22,0,0,1-6.4,15.5A21.54,21.54,0,0,1,30.07,51.9Z"/></svg>');
  background-size: 1em 1em;
  background-repeat: no-repeat;
  background-position: 50%;
  pointer-events: none;
}

.formulate-input[data-classification="text"]
  .formulate-input-element--search
  input {
  padding-left: 2em;
}

.formulate-input[data-classification="text"] input[type="color"] {
  height: 1.1em;
  box-sizing: content-box;
  width: auto;
  min-width: 5em;
}

.formulate-input[data-classification="text"]
  input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0 0 0 1.5em;
  display: flex;
  align-items: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.17 79.17"><path fill="%236d6d6d" d="M40.8,22.92c-3.4-3.4-4.76-8.44-1-12.24s8.84-2.44,12.24,1c5-5,10.69-13.33,18.81-11.31a11,11,0,0,1,7.62,14.34c-1.26,3.45-4.63,6.06-7.16,8.59-.92.93-3,2.26-3.46,3.46-.42,1,1.82,2.63,2.36,4a8,8,0,0,1-12.42,9.19c-.21-.16-1.35-1.51-1.59-1.51s-.83.83-1,1L49.71,44.9,32.43,62.18c-3.37,3.38-6.6,7.14-10.26,10.21a11,11,0,0,1-4.48,2.28c-1.25.3-3.11-.22-4.18.18-1.28.48-2.42,2.65-3.68,3.4-6.05,3.61-12.64-4-8.46-9.57.73-1,2.53-1.92,3-3a14.46,14.46,0,0,0-.09-2.52,10.75,10.75,0,0,1,3.14-6.77c.92-1,1.93-1.93,2.89-2.9Zm4.4-1.5c4.19,4,8.24,8.24,12.36,12.36,2.06,2.06,5,5.59,8,2.61,4.65-4.62-5-6.8-2.42-10.78C66.3,20.7,76.4,16.48,74.84,9.45,73.62,4,67.12,2.78,63.29,6.32c-2.55,2.36-4.93,4.94-7.39,7.4-.79.78-1.8,2.28-2.88,2.73-2.14.88-3.4-1.62-4.79-2.77-2.58-2.14-6.89-.82-6.53,3C41.89,18.68,43.87,20.09,45.2,21.42Zm-1.45,4.44L27.82,41.79C22,47.57,15.89,53.14,10.41,59.2a8.23,8.23,0,0,0-1.44,2c-.93,2,.25,4.14-.5,6S4.92,69.94,4.3,72a2.34,2.34,0,0,0,2.56,3c1.11-.17,2-1.33,2.71-2.07a11.17,11.17,0,0,1,2.08-2c1.68-.94,4,.17,5.93-.57C20,69.41,22,66.73,23.76,65L34.42,54.3,53.3,35.42Z"/></svg>');
  background-repeat: no-repeat;
  background-size: 0.9em 0.9em;
  background-position: left 0.1em;
}

.formulate-input[data-classification="text"]
  input[type="color"]::-webkit-color-swatch {
  display: block;
  height: 1em;
  border-radius: 0.2em;
  border: 0;
  flex: auto;
}

.formulate-input[data-classification="text"]
  input[type="color"]::-moz-color-swatch {
  display: block;
  height: 1em;
  border-radius: 0.2em;
  border: 0;
  flex: auto;
}
.formulate-input[data-classification="slider"] .formulate-input-element--range {
  display: flex;
  align-items: center;
}

.formulate-input[data-classification="slider"]
  .formulate-input-element-range-value {
  font-size: 0.9em;
  line-height: 1;
  margin-left: 0.5em;
  background-color: #efefef;
  padding: 0.25em 0.3em;
  border-radius: 0.25em;
  color: #6d6d6d;
  font-variant-numeric: tabular-nums;
}
.formulate-input[data-classification="slider"] input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 1em;
  padding: 0.5em 0;
}

.formulate-input[data-classification="slider"] input:focus {
  outline: 0;
}
.formulate-input[data-classification="slider"] input::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  border: 0;
  background-color: #a5ca19;
  margin-top: calc(-0.5em + 2px);
}

.formulate-input[data-classification="slider"] input::-moz-range-thumb {
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  border: 0;
  background-color: #a5ca19;
  margin-top: calc(-0.5em + 2px);
}
.formulate-input[data-classification="slider"] input::-ms-thumb {
  cursor: pointer;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  border: 0;
  background-color: #61b231;
  margin-top: calc(-0.5em + 2px);
}
.formulate-input[data-classification="slider"]
  input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background-color: #efefef;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

.formulate-input[data-classification="slider"] input::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background-color: #efefef;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}
.formulate-input[data-classification="textarea"] textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
}

.formulate-input[data-classification="textarea"] textarea::-moz-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="textarea"]
  textarea:-ms-input-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="textarea"] textarea::placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="textarea"] textarea:focus {
  outline: 0;
  border: 1px solid #61b231;
}

.formulate-input[data-classification="button"] button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2em;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #a5ca19;
  background-color: #a5ca19;
  color: #fff;
  min-width: 0;
  width: auto;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  min-width: -webkit-fill-available;
  justify-content: center;
}

.formulate-input[data-classification="button"] button:hover:not([disabled]) {
  background-color: white;
  color: #a5ca19;
  cursor: pointer;
}

.formulate-input[data-classification="button"] button::-moz-placeholder {
  color: #a8a8a8;
  line-height: normal;
}

.formulate-input[data-classification="button"] button:-ms-input-placeholder {
  color: #a8a8a8;
  line-height: normal;
}

.formulate-input[data-classification="button"] button::placeholder {
  color: #a8a8a8;
  line-height: normal;
}

.formulate-input[data-classification="button"] button:focus {
  outline: 0;
  border: 1px solid #a5ca19;
}
.formulate-input[data-classification="button"] button[disabled],
.formulate-input[data-classification="button"] button[disabled]:active {
  background-color: #cecece;
  border-color: #cecece;
}
.formulate-input[data-classification="button"] button[data-ghost] {
  color: #a5ca19;
  background-color: transparent;
  border-color: currentColor;
}
.formulate-input[data-classification="button"] button[data-minor] {
  font-size: 0.75em;
  display: inline-block;
}
.formulate-input[data-classification="button"] button[data-danger] {
  background-color: #960505;
  border-color: #960505;
}
.formulate-input[data-classification="button"] button[data-danger][data-ghost] {
  color: #960505;
  background-color: transparent;
}
.formulate-input[data-classification="button"] button:active {
  background-color: #a5ca19;
  border-color: #a5ca19;
}
.formulate-input[data-classification="select"] .formulate-input-element {
  position: relative;
}
.formulate-input[data-classification="select"] .formulate-input-element:before {
  content: "";
  width: 0;
  height: 0;
  border-color: #cecece transparent transparent;
  border-style: solid;
  border-width: 0.3em 0.3em 0;
  top: 50%;
  margin-top: -0.1em;
  right: 1em;
  position: absolute;
}
.formulate-input[data-classification="select"]
  .formulate-input-element[data-multiple]:before {
  display: none;
}
.formulate-input[data-classification="select"] select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  padding: 0.75em 2em 0.75em 0.75em;
}
.formulate-input[data-classification="select"] select::-moz-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="select"] select:-ms-input-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="select"] select::placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="select"] select:focus {
  outline: 0;
  border: 1px solid #a5ca19;
}
.formulate-input[data-classification="select"] select option {
  color: #000;
}
.formulate-input[data-classification="select"] select option:disabled {
  color: #a8a8a8;
}
.formulate-input[data-classification="select"] select[multiple] {
  padding: 0;
  overflow-y: auto;
}
.formulate-input[data-classification="select"] select[multiple] option {
  padding: 0.75em;
}
.formulate-input[data-classification="select"]
  select[data-placeholder-selected] {
  color: #a8a8a8;
}
.formulate-input[data-classification="box"] .formulate-input-wrapper {
  display: flex;
  align-items: center;
}
.formulate-input[data-classification="box"] .formulate-input-element {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.formulate-input[data-classification="box"] .formulate-input-element input {
  position: absolute;
  left: -999px;
  opacity: 0;
  pointer-events: none;
}
.formulate-input[data-classification="box"] .formulate-input-element-decorator {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 1px solid #cecece;
  position: relative;
}
.formulate-input[data-classification="box"]
  .formulate-input-element-decorator:before {
  content: "";
  display: block;
  background-size: contain;
  background-position: 100%;
  width: calc(100% - 0.125em);
  height: calc(100% - 0.125em);
  box-sizing: border-box;
  position: absolute;
  top: 0.0625em;
  left: 0.0625em;
}
.formulate-input[data-classification="box"]
  .formulate-input-element[data-type="radio"]
  .formulate-input-element-decorator {
  border-radius: 1em;
}
.formulate-input[data-classification="box"]
  .formulate-input-element[data-type="radio"]
  .formulate-input-element-decorator:before {
  border-radius: 1em;
  width: calc(100% - 0.5em);
  height: calc(100% - 0.5em);
  top: 0.25em;
  left: 0.25em;
}
.formulate-input[data-classification="box"]
  .formulate-input-element
  input[type="checkbox"]:checked
  ~ .formulate-input-element-decorator {
  border-color: #a5ca19;
}
.formulate-input[data-classification="box"]
  .formulate-input-element
  input[type="checkbox"]:checked
  ~ .formulate-input-element-decorator:before {
  background-color: #a5ca19;
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>')
    no-repeat;
  -webkit-mask-repeat: no-repeat;
}
.formulate-input[data-classification="box"]
  .formulate-input-element
  input[type="radio"]:checked
  ~ .formulate-input-element-decorator {
  border-color: #a5ca19;
}
.formulate-input[data-classification="box"]
  .formulate-input-element
  input[type="radio"]:checked
  ~ .formulate-input-element-decorator:before {
  background-color: #a5ca19;
}
.formulate-input[data-classification="box"]
  .formulate-input-element
  input:focus
  ~ .formulate-input-element-decorator {
  border-color: #a5ca19;
}
.formulate-input[data-classification="box"] .formulate-input-label--after {
  margin-left: 0.5em;
}
.formulate-input[data-classification="box"] .formulate-input-label--before {
  margin-right: 0.5em;
}
.formulate-input[data-classification="group"] .formulate-input-group-item,
.formulate-input[data-classification="group"]
  > .formulate-input-wrapper
  > .formulate-input-label {
  margin-bottom: 0.5em;
}
.formulate-input[data-classification="group"] [data-is-repeatable] {
  padding: 1em;
  border: 1px solid #efefef;
  border-radius: 0.3em;
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-grouping {
  margin: -1em -1em 0;
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable {
  padding: 1em 3em 1em 1em;
  border-bottom: 1px solid #efefef;
  position: relative;
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove {
  position: absolute;
  display: block;
  top: calc(50% - 0.15em);
  width: 1.3em;
  height: 1.3em;
  background-color: #cecece;
  right: 0.85em;
  border-radius: 1.3em;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
  text-indent: -1000px;
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove[data-disabled] {
  opacity: 0.2;
  pointer-events: none;
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove:after,
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove:before {
  content: "";
  position: absolute;
  top: calc(50% - 0.1em);
  left: 0.325em;
  display: block;
  width: 0.65em;
  height: 0.2em;
  background-color: #fff;
  transform-origin: center center;
  transition: transform 0.25s;
}
@media (pointer: fine) {
  .formulate-input[data-classification="group"]
    [data-is-repeatable]
    .formulate-input-group-repeatable-remove:hover {
    background-color: #dc2c2c;
  }
  .formulate-input[data-classification="group"]
    [data-is-repeatable]
    .formulate-input-group-repeatable-remove:hover:after,
  .formulate-input[data-classification="group"]
    [data-is-repeatable]
    .formulate-input-group-repeatable-remove:hover:before {
    height: 0.2em;
    width: 0.75em;
    left: 0.25em;
    top: calc(50% - 0.075em);
  }
  .formulate-input[data-classification="group"]
    [data-is-repeatable]
    .formulate-input-group-repeatable-remove:hover:after {
    transform: rotate(45deg);
  }
  .formulate-input[data-classification="group"]
    [data-is-repeatable]
    .formulate-input-group-repeatable-remove:hover:before {
    transform: rotate(-45deg);
  }
}
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable:last-child {
  margin-bottom: 1em;
}
.formulate-input[data-classification="file"] .formulate-input-upload-area {
  width: 100%;
  position: relative;
  padding: 2em 0;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area[data-has-files] {
  padding: 0;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area[data-has-files]
  input {
  display: none;
}
.formulate-input[data-classification="file"] .formulate-input-upload-area-mask {
  border-radius: 0.4em;
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 2px dashed #a8a8a8;
  z-index: 2;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area-mask:before {
  content: "";
  background-color: #a8a8a8;
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><path d="M29,58A29,29,0,1,0,0,29,29,29,0,0,0,29,58ZM29,4A25,25,0,1,1,4,29,25,25,0,0,1,29,4Z"/><polygon points="27 22 27 44.4 31 44.4 31 22 41.7 31.1 44.3 28.1 29 15 13.7 28.1 16.3 31.1 27 22"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 2em;
  height: 2em;
  position: absolute;
  pointer-events: none;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input:focus
  ~ .formulate-input-upload-area-mask,
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input:hover
  ~ .formulate-input-upload-area-mask,
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input[data-is-drag-hover]
  ~ .formulate-input-upload-area-mask {
  border-color: #41b883;
}
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input:focus
  ~ .formulate-input-upload-area-mask:before,
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input:hover
  ~ .formulate-input-upload-area-mask:before,
.formulate-input[data-classification="file"]
  .formulate-input-upload-area
  input[data-is-drag-hover]
  ~ .formulate-input-upload-area-mask:before {
  background-color: #61b231;
}
.formulate-input[data-classification="file"] .formulate-files {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-add {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  min-width: 75px;
  border: 1px solid #61b231;
  color: #61b231;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  font-size: 13px;
  background-color: transparent;
  cursor: pointer;
  margin: 0.5em 0;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-add
  input {
  position: absolute;
  display: block;
  cursor: pointer;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-add
  input::-webkit-file-upload-button {
  display: none;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress {
  background-color: #cecece;
  height: 0.3em;
  border-radius: 1.25em;
  width: 5em;
  overflow: hidden;
  position: relative;
  flex: 0 0 5em;
  right: 0.75em;
  transition: height 0.25s, width 0.25s, flex-basis 0.25s;
  z-index: 2;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 0;
  transform: scale(0.08);
  background-color: #fff;
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
  -webkit-mask-size: 77%;
  mask-size: 77%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  z-index: 3;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress[data-just-finished] {
  width: 1.25em;
  height: 1.25em;
  flex: 0 0 1.25em;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress[data-just-finished]:before {
  transition: transform 0.25s 0.2s, opacity 0.25s 0.2s;
  transform: scale(1);
  opacity: 1;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress[data-is-finished] {
  transition: height 0.25s, width 0.25s, left 0.25s, top, 0.25s,
    border-radius 0.25s;
  width: 0.3em;
  flex: 0 0 0.3em;
  height: 100%;
  right: 0;
  border-radius: 0 0.23em 0.23em 0;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress[data-is-finished]:before {
  transition: opacity 0.1s;
  opacity: 0;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-progress
  .formulate-file-progress-inner {
  background-color: #61b231;
  width: 1%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-name {
  padding-left: 1.5em;
  padding-right: 2em;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-name:before {
  position: absolute;
  left: 0.7em;
  top: 50%;
  margin-top: -0.7em;
  background-color: #a8a8a8;
  content: "";
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  margin-right: 0.5em;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-remove {
  width: 1.25em;
  height: 1.25em;
  border-radius: 1em;
  border: 1px solid #a8a8a8;
  background-color: #a8a8a8;
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2"><path d="M1.6,57.7a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5l21-21,21,21a5,5,0,0,0,3.5,1.5,4.85,4.85,0,0,0,3.5-1.5,5,5,0,0,0,0-7.1l-21-21,21-21a5,5,0,0,0,0-7.1,5,5,0,0,0-7.1,0l-21,21L8.6,1.7a5,5,0,0,0-7.1,0,5,5,0,0,0,0,7.1l21,21L1.6,50.7A4.83,4.83,0,0,0,1.6,57.7Z"/></svg>');
  -webkit-mask-size: 0.6em;
  mask-size: 0.6em;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  cursor: pointer;
  position: absolute;
  right: 0.75em;
  z-index: 1;
  transition: transform 0.25s;
}
@media (pointer: fine) {
  .formulate-input[data-classification="file"]
    .formulate-files
    .formulate-file-remove:hover {
    transform: scale(1.5);
  }
}
.formulate-input[data-classification="file"] .formulate-files li {
  display: block;
}
.formulate-input[data-classification="file"]
  .formulate-files
  li[data-has-error]
  .formulate-file-progress {
  background-color: #dc2c2c;
}
.formulate-input[data-classification="file"]
  .formulate-files
  li[data-has-preview]
  .formulate-file-name:before {
  display: none;
}
.formulate-input[data-classification="file"] .formulate-files li + li {
  margin-top: 0.5em;
}
.formulate-input[data-classification="file"] .formulate-files .formulate-file {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 0.9em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  padding: 0.75em;
  display: block;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file::-moz-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file:-ms-input-placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file::placeholder {
  color: #a8a8a8;
  line-height: normal;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file:focus {
  outline: 0;
  border: 1px solid #41b883;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file
  ::-webkit-progress-bar {
  -webkit-appearance: none;
  appearance: none;
  height: 0.5em;
  border-radius: 0.5em;
  overflow: hidden;
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-image-preview {
  width: 3em;
  height: 3em;
  flex: 0 0 3em;
  position: relative;
  z-index: 2;
  left: -1px;
  box-shadow: 0 0 0 1px #efefef;
  transition: transform 0.25s, box-shadow 0.25s, background-color 0.25s;
}
@media (pointer: fine) {
  .formulate-input[data-classification="file"]
    .formulate-files
    .formulate-file-image-preview:hover {
    transition-delay: 0.2s;
    transform: scale(3);
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  }
}
.formulate-input[data-classification="file"]
  .formulate-files
  .formulate-file-image-preview
  img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
  transition: all 0.25s;
}
.formulate-input[data-classification="file"]
  [data-type="image"]
  .formulate-input-upload-area
  .formulate-input-upload-area-mask:before {
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 71.05"><path d="M82.89,0H7.1A7.12,7.12,0,0,0,0,7.11V64a7.11,7.11,0,0,0,7.1,7.1H82.9A7.11,7.11,0,0,0,90,64V7.11A7.12,7.12,0,0,0,82.89,0ZM69.28,39.35a5.44,5.44,0,0,0-8,0L50.58,50.74,32.38,30.88a5.31,5.31,0,0,0-7.92,0L4.74,52.4V7.11A2.37,2.37,0,0,1,7.11,4.74H82.9a2.37,2.37,0,0,1,2.36,2.37V56.3Z"/><circle cx="67.74" cy="22.26" r="8.53"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 71.05"><path d="M82.89,0H7.1A7.12,7.12,0,0,0,0,7.11V64a7.11,7.11,0,0,0,7.1,7.1H82.9A7.11,7.11,0,0,0,90,64V7.11A7.12,7.12,0,0,0,82.89,0ZM69.28,39.35a5.44,5.44,0,0,0-8,0L50.58,50.74,32.38,30.88a5.31,5.31,0,0,0-7.92,0L4.74,52.4V7.11A2.37,2.37,0,0,1,7.11,4.74H82.9a2.37,2.37,0,0,1,2.36,2.37V56.3Z"/><circle cx="67.74" cy="22.26" r="8.53"/></svg>');
}
.formulate-form-errors {
  margin: 0.75em 0;
  padding: 0;
  list-style-type: none;
}
.formulate-form-errors:first-child {
  margin-top: 0;
}
.formulate-form-errors:last-child {
  margin-bottom: 0;
}
.formulate-form-errors .formulate-form-error {
  color: #960505;
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0.25em;
} */
</style>
