<template>
  <div
    id="avatar"
    v-if="currentUser && currentUser.photoURL"
    class="avatar w-16 h-16 border-0 rounded-full m-1 bg-cover shadow"
  ></div>
  <div
    v-else-if="activeUser && activeUser.avatar"
    :class="
      `w-16 h-16 border-0 rounded-full m-1 bg-cover bg-white bg-${activeUser.avatar} flex flex-col items-center align-center justify-center text-4xl shadow`
    "
  ></div>
  <div
    v-else
    class="w-16 h-16 border-0 rounded-full bg-white shadow flex flex-col justify-center items-center"
  >
    {{ firstLetter }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["currentUser", "activeUser"]),
    firstLetter() {
      if (this.currentUser) {
        return this.currentUser.displayName.slice(0, 1);
      }
      return null;
    }
  },
  mounted() {
    document.getElementsByClassName("avatar").forEach(element => {
      if (this.currentUser.photoURL) {
        element.style.backgroundImage = `url(${this.currentUser.photoURL})`;
      }
    });
  }
};
</script>

<style>
/* #avatar {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/pangea-pp.appspot.com/o/users%2FBf0Ybtl48RNuvsr5gPkoVTeW1yf2%2Favatar.jpg?alt=media&token=ef817aed-ccbb-4c7b-9b9d-38b44ba60dbf");
} */
</style>
