<template>
  <div id="the-filter" class="m-0 m-auto h-full w-full sm:w-1/2">
    <FormulateForm #default="{ hasErrors }">
      <FormulateInput
        type="select"
        name="Jahrgang"
        :options="{
          '3': 'Jahrgang 3',
          '4': 'Jahrgang 4',
          '5': 'Jahrgang 5',
          '6': 'Jahrgang 6',
          '7': 'Jahrgang 7',
          '8': 'Jahrgang 8',
          '9': 'Jahrgang 9',
          '10': 'Jahrgang 10'
        }"
        placeholder="Jahrgang auswählen..."
        v-model="grade"
        validation="required"
        validation-behavior="live"
      />
      <FormulateInput
        type="select"
        name="Schwierigkeit"
        :options="{
          1: '1-Punkt-Aufgaben',
          2: '2-Punkte-Aufgaben',
          3: '3-Punkte-Aufgaben',
          4: '4-Punkte-Aufgaben',
          5: '5-Punkte-Aufgaben'
        }"
        placeholder="Schwierigkeit auswählen..."
        v-model.number="points"
        validation="required"
        validation-behavior="live"
      />
      <FormulateInput
        type="select"
        :options="{
          Algebra: 'Algebra',
          Arithmetik: 'Arithmetik',
          Funktionen: 'Funktionen',
          Geometrie: 'Geometrie',
          Kombinatorik: 'Kombinatorik',
          Logik: 'Logik',
          Wahrscheinlichkeit: 'Wahrscheinlichkeit',
          Zahlen: 'Zahlen',
          '': 'keine Auswahl'
        }"
        placeholder="Themengebiet auswählen..."
        v-model="topic"
        help="Optional"
      />
      <!--
      <FormulateInput
        type="select"
        name="Erscheinungsjahr"
        :options="{
          '2020': '2020',
          '2019': '2019',
          '': 'keine Auswahl'
        }"
        placeholder="Erscheinungsjahr auswählen..."
        v-model="year"
        help="Optional"
      />
      -->
      <FormulateInput
        type="submit"
        value="Filtern"
        @click="filter(grade, points, topic, year)"
        :disabled="hasErrors"
      />
    </FormulateForm>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      points: undefined,
      isSolved: false,
      grade: undefined,
      topic: undefined,
      year: undefined
    };
  },
  methods: {
    ...mapActions(["bindProblems"]),
    filter(grade, points, topic, year) {
      if (grade && points && !topic && !year) {
        this.bindProblems({
          keys: ["grade", "points"],
          values: [grade, points]
        });
      }

      if (grade && points && topic && !year) {
        this.bindProblems({
          keys: ["grade", "points", "topic"],
          values: [grade, points, topic]
        });
      }

      if (grade && points && topic && year) {
        this.bindProblems({
          keys: ["grade", "points", "topic", "year"],
          values: [grade, points, topic, year]
        });
      }

      if (grade && points && !topic && year) {
        this.bindProblems({
          keys: ["grade", "points", "year"],
          values: [grade, points, year]
        });
      }

      this.$emit("").$options.parent.$children.forEach(problem => {
        if (problem.feedback) {
          problem.pickedAnswer = "";
          problem.feedback.text = "";
          problem.feedback.show = false;
        }
      });

      setTimeout(() => {
        this.$emit("").$options.parent.showProblems = true;
      }, 500);
    }
  }
};
</script>

<style>
/* 
.filter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.filter-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 17px;
}

.filter input[type="radio"] {
    height: 1em;
}

.filter input[type="button"] {
    background: black;
    border: solid 2px black;
    padding: 5px 0 5px 0;
    margin: 20px 0 10px 0;
}

.filter select {
    min-width: 220px;
    font-size: 14pt;
    padding: 5px;
    border: 2px solid black;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

.filter select:hover {
    color: black;
    background: white;
} */
</style>
