<template>
  <div
    v-if="currentUser"
    id="drop-down"
    class="absolute flex flex-col items-center bg-black text-yellow-50 font-semibold rounded-b z-50 pt-1 -top-1 right-0 shadow-xl"
  >
    <div class="flex items-center border-b pb-2 pl-1 bg-black border-yellow-50">
      <ShortUserInfo />
      <TheAvatar class="text-black" />
    </div>
    <ul class="text-base pt-2 list-none">
      <li class="pt-1.5 pb-1.5 list-none ml-2">
        <span class="lnr lnr-user"></span>
        <router-link to="/userprofile">
          <span class="hover:underline cursor-pointer px-1"
            >Profil bearbeiten</span
          >
        </router-link>
      </li>
      <li class="pt-1.5 pb-1.5 list-none ml-2">
        <span class="lnr lnr-exit"></span>
        <span class="hover:underline cursor-pointer px-1" @click="signOut"
          >Abmelden</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import TheAvatar from "@/components/TheAvatar.vue";
import ShortUserInfo from "@/components/ShortUserInfo.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "TheDropDownMenu",
  components: {
    TheAvatar,
    ShortUserInfo
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    ...mapActions(["signOutAction"]),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace("/signin");
      });
    }
  }
};
</script>

<style></style>
