import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import { de } from "@braid/vue-formulate-i18n";

Vue.use(VueFormulate, {
  plugins: [de],
  locale: "de",
  classes: {
    outer(context) {
      switch (context.classification) {
        case "button":
          if (context.value === "Prüfen") {
            return "mb-0";
          } else {
            return "mb-4 text-center";
          }
        default:
          return "mb-4";
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case "box":
          return "flex items-center space-x-1 text-lg";
        default:
          return;
      }
    },
    input(context) {
      switch (context.classification) {
        case "select":
          return "bg-chevronDown bg-no-repeat bg-right-4 bg-4 px-3 py-2 border rounded appearance-none focus:border-pangea-yellow outline-none border-box w-full shadow";
        case "button":
          if (context.attrs.disabled) {
            return "cursor-auto px-3 py-2 rounded-full bg-gray-300 text-white font-semibold w-44 appearance-none focus:outline-none border-box shadow-lg";
          } else {
            let base =
              "px-3 py-2 rounded-full text-center font-semibold appearance-none focus:outline-none border-box shadow-lg transform hover:scale-105 ";
            if (context.value === "Registrieren") {
              base += "w-44 bg-pangea-yellow text-yellow-50";
            } else if (context.value === "Anmelden") {
              base += "w-44 bg-pangea-green text-yellow-50";
            } else if (context.value === "Filtern") {
              base += "w-44 bg-yellow-50";
            } else {
              return (base += "w-44 bg-black text-yellow-50 mb-0");
            }
            return base;
          }
        case "box":
          // if (context.hasValue) {
          return "sr-only";
        // return "w-4 h-4 bg-squareCheck bg-contain bg-center appearance-none outline-none border-box";
        // } else {
        //   return "w-4 h-4 bg-square bg-contain bg-center appearance-none outline-none border-box";
        // }
        case "slider":
          return "w-full bg-yellow-50 border border-black rounded-full cursor-pointer appearance-none pointer-events-auto outline-none h-2 focus:none";
        default:
          return "px-3 py-2 w-1 border rounded appearance-none focus:border-pangea-yellow outline-none border-box w-full shadow-inner";
      }
    },
    element(context) {
      switch (context.classification) {
        case "box":
          return "relative flex flex-col items-center mb-0.5";
        default:
          return "p-0";
      }
    },
    decorator({ hasValue }) {
      let base =
        "bg-white border rounded inline-block w-4 h-4 mr-2 shadow-inner";
      if (hasValue) {
        base += "border-pangea-green bg-checkMark bg-contain";
      }
      return base;
    },
    label: "font-medium text-sm",
    help: "text-xs mb-1 text-gray-600 mt-1",
    error: "text-red-700 text-xs mb-1"
  }
});
