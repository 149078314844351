import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: "AIzaSyAF7IBnHQxWs8yThsuXAjQhK7WUzknZ8-E",
    authDomain: "pangea-pp.firebaseapp.com",
    projectId: "pangea-pp",
    storageBucket: "pangea-pp.appspot.com",
    messagingSenderId: "487205426461",
    appId: "1:487205426461:web:4ef4e0c84ab94713a7bdf4"
  };
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

Vue.use(firebase);
