<template>
  <div id="delete-account" class="m-0 m-auto sm:max-w-sm">
    <TheTitle :title="'Account löschen'" />
    <div>
      Um dein Account zu löschen, musst du deine E-Mail-Adresse und dein
      Passwort eingeben. Es werden dann alle Daten unwiderruflich gelöscht.
      <br />
      <br />
      <FormulateForm
        #default="{hasErrors}"
        class="bg-gray-50 rounded px-5 pt-5 pb-1"
      >
        <FormulateInput
          type="text"
          placeholder="E-Mail"
          v-model="email"
          validation="required|email"
        />
        <FormulateInput
          type="password"
          v-model="password"
          placeholder="Passwort"
          @keypress.enter="deleteAccount"
          validation="required"
          validation-name="Passwort"
        />
        <FormulateInput
          type="button"
          value="Account löschen"
          :disabled="hasErrors"
          @click.prevent="deleteAccount"
        />
      </FormulateForm>
    </div>
    <div class="text-center mt-5 text-sm">
      <router-link to="/userprofile" class="hover:underline"
        >zurück zur Profilseite</router-link
      >
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase.js";
import firebase from "firebase/app";
import { mapState, mapActions } from "vuex";
import TheTitle from "@/components/TheTitle.vue";

export default {
  name: "DeleteAccount",
  components: {
    TheTitle
  },
  data() {
    return {
      email: "",
      password: "",
      successMessage: "Dein Account wurde unwideruflich gelöscht."
    };
  },
  computed: {
    ...mapState(["activeUser", "currentUser", "error"])
  },
  methods: {
    ...mapActions(["errorMessageFromFirebase"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/signup");
      }, 3000);
    },
    deleteAccount() {
      let decrement = firebase.firestore.FieldValue.increment(-1);
      let credential = firebase.auth.EmailAuthProvider.credential(
        this.email,
        this.password
      );
      auth.currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          const docRef = db.collection("users").doc(this.currentUser.uid);
          docRef.get().then(doc => {
            let federalState = doc.data().federalState;
            let gender = doc.data().gender;
            let school = doc.data().school;
            let grade = doc.data().grade;
            db.collection("statistics")
              .doc("gender")
              .update({ [`${gender}`]: decrement });
            db.collection("statistics")
              .doc("gender")
              .update({ total: decrement });
            db.collection("statistics")
              .doc("school")
              .update({ [`${school}`]: decrement });
            db.collection("statistics")
              .doc("school")
              .update({ total: decrement });
            db.collection("statistics")
              .doc("grades")
              .update({ [`${grade}`]: decrement });
            db.collection("statistics")
              .doc("grades")
              .update({ total: decrement });
            db.collection("statistics")
              .doc("federalStates")
              .update({ [`${federalState}`]: decrement });
            db.collection("statistics")
              .doc("federalStates")
              .update({ total: decrement })
              .then(() => {
                auth.currentUser.delete().then(() => {
                  this.successNotification();
                  // this.$router.replace("/signup");
                });
              })
              .catch(error => {
                console.log(error.message);
                this.errorMessageFromFirebase(error);
                this.errorNotification();
              });
          });
        })
        .catch(error => {
          console.log(error);
          this.errorMessageFromFirebase(error);
          this.errorNotification();
        });
      return;
    }
  }
};
</script>

<style></style>
