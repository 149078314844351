var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser)?_c('div',{attrs:{"id":"random-problem"}},[_c('FormulateForm',{staticClass:"m-0 m-auto sm:w-1/2 md:w-1/3 lg:w-1/4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"type":"select","name":"Jahrgang","options":{
        '3': 'Jahrgang 3',
        '4': 'Jahrgang 4',
        '5': 'Jahrgang 5',
        '6': 'Jahrgang 6',
        '7': 'Jahrgang 7',
        '8': 'Jahrgang 8',
        '9': 'Jahrgang 9',
        '10': 'Jahrgang 10'
      },"placeholder":"Jahrgang auswählen...","validation":"required","validation-behavior":"live"},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('FormulateInput',{attrs:{"type":"submit","value":"Neue Aufgabe","disabled":hasErrors},on:{"click":function($event){return _vm.createProblem(_vm.grade)}}})]}}],null,false,1402888478)}),_vm._l((_vm.problems),function(problem,index){return _c('div',{key:index,staticClass:"mb-10"},[_c('Problem',{key:index,attrs:{"attemptBy":problem.attemptBy[_vm.currentUser.displayName],"choices":problem.choices,"problemId":problem.id,"likes":problem.likes,"dislikes":problem.dislikes,"isLikedBy":problem.isLikedBy[_vm.currentUser.displayName],"isDislikedBy":problem.isDislikedBy[_vm.currentUser.displayName],"isSolvedBy":problem.isSolvedBy[_vm.currentUser.displayName],"points":problem.points,"textFirst":problem.text,"textSecond":problem.secondText,"textThird":problem.thirdText,"svg":problem.svg,"anotherSvg":problem.anotherSvg}})],1)}),(_vm.randomProblem)?_c('div',[_vm._v(" "+_vm._s(_vm.currentUser.displayName)+" "+_vm._s(_vm.randomProblem[0].isLikedBy[_vm.currentUser.displayName])+" "+_vm._s(_vm.randomProblem[0].attemptBy[_vm.currentUser.displayName])+" "),_c('Problem',{key:_vm.randomProblem,attrs:{"attemptBy":_vm.randomProblem[0].attemptBy[_vm.currentUser.displayName],"choices":_vm.randomProblem[0].choices,"problemId":_vm.randomProblem[0].id,"likes":_vm.randomProblem[0].likes,"dislikes":_vm.randomProblem[0].dislikes,"isLikedBy":_vm.randomProblem[0].isLikedBy[_vm.currentUser.displayName],"isDislikedBy":_vm.randomProblem[0].isDislikedBy[_vm.currentUser.displayName],"isSolvedBy":_vm.randomProblem[0].isSolvedBy[_vm.currentUser.displayName],"points":_vm.randomProblem[0].points,"textFirst":_vm.randomProblem[0].text,"textSecond":_vm.randomProblem[0].secondText,"textThird":_vm.randomProblem[0].thirdText,"svg":_vm.randomProblem[0].svg,"anotherSvg":_vm.randomProblem[0].anotherSvg}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }