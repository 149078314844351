import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { db, auth } from "@/firebase";
import firebase from "firebase/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeUser: null,
    currentUser: null,
    error: null,
    nicknames: null,
    problems: null,
    statistics: null,
    students: null
  },

  mutations: {
    ...vuexfireMutations,
    setUser(state, payload) {
      state.currentUser = payload;
    },
    setError(state, payload) {
      state.error = payload;
    }
  },

  actions: {
    bindProblems: firestoreAction(async ({ bindFirestoreRef }, payload) => {
      if (!payload) {
        const ref = await db.collection("problems").orderBy("points");
        bindFirestoreRef("problems", ref, { wait: true });
      } else {
        if (
          payload.keys &&
          payload.keys.includes("grade") &&
          payload.keys.includes("points") &&
          !payload.keys.includes("topic") &&
          !payload.keys.includes("year")
        ) {
          const ref = await db
            .collection("problems")
            .where("grades", "array-contains", payload.values[0])
            .where("points", "==", payload.values[1]);
          await bindFirestoreRef("problems", ref, { wait: true });
        }

        if (
          payload.keys &&
          payload.keys.includes("grade") &&
          payload.keys.includes("points") &&
          payload.keys.includes("topic") &&
          !payload.keys.includes("year")
        ) {
          const ref = await db
            .collection("problems")
            .where("grades", "array-contains", payload.values[0])
            .where("points", "==", payload.values[1])
            .where("topic", "==", payload.values[2]);
          await bindFirestoreRef("problems", ref, { wait: true });
        }

        if (
          payload.keys &&
          payload.keys.includes("grade") &&
          payload.keys.includes("points") &&
          payload.keys.includes("topic") &&
          payload.keys.includes("year")
        ) {
          const ref = await db
            .collection("problems")
            .where("grades", "array-contains", payload.values[0])
            .where("points", "==", payload.values[1])
            .where("topic", "==", payload.values[2])
            .where("year", "==", payload.values[3]);
          await bindFirestoreRef("problems", ref, { wait: true });
        }

        if (
          payload.keys &&
          payload.keys.includes("grade") &&
          payload.keys.includes("points") &&
          !payload.keys.includes("topic") &&
          payload.keys.includes("year")
        ) {
          const ref = await db
            .collection("problems")
            .where("grades", "array-contains", payload.values[0])
            .where("points", "==", payload.values[1])
            .where("year", "==", payload.values[2]);
          await bindFirestoreRef("problems", ref, { wait: true });
        }

        // if (payload.key === "year") {
        //   const ref = await db
        //     .collection("problems")
        //     .where("year", "==", payload.value)
        //     .orderBy("points", "asc");
        //   bindFirestoreRef("problems", ref, { wait: true });
        // }
        let key = db.collection("problems").doc().id;
        if (
          payload.keys &&
          payload.keys.includes("grade") &&
          !payload.keys.includes("points") &&
          !payload.keys.includes("topic") &&
          !payload.keys.includes("year")
        ) {
          const ref = await db
            .collection("problems")
            .where("grades", "array-contains", payload.values[0])
            .where(firebase.firestore.FieldPath.documentId(), ">=", key)
            .limit(1);
          ref.get().then(data => {
            console.log(data.empty);
          });
          bindFirestoreRef("problems", ref, { wait: true });
        }
        if (payload === "likes") {
          const ref = await db
            .collection("problems")
            .orderBy("likes", "desc")
            .limit(3);
          bindFirestoreRef("problems", ref, { wait: true });
        }
      }
    }),
    unbindProblems: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("problems");
    }),

    bindActiveUser: firestoreAction(async ({ bindFirestoreRef, state }) => {
      if (state.currentUser) {
        const ref = await db.collection("users").doc(state.currentUser.uid);
        bindFirestoreRef("activeUser", ref, { wait: true });
      }
    }),
    unbindActiveUser: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("activeUser");
    }),

    bindNicknames: firestoreAction(async ({ bindFirestoreRef }) => {
      const ref = await db.collection("nicknames");
      bindFirestoreRef("nicknames", ref, { wait: true });
    }),
    unbindNicknames: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("nicknames");
    }),

    bindStatistics: firestoreAction(async ({ bindFirestoreRef }) => {
      const ref = await db.collection("statistics");
      bindFirestoreRef("statistics", ref, { wait: true });
    }),
    unbindStatisticss: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("statistics");
    }),

    bindStudents: firestoreAction(async ({ bindFirestoreRef }) => {
      const ref = await db.collection("users").orderBy("points", "desc");
      const students = ref.where("userType", "==", "Schüler*in");
      bindFirestoreRef("students", students, { wait: true });
    }),
    unbindStudents: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("students");
    }),
    errorMessageFromFirebase({ commit }, payload) {
      switch (payload.code) {
        case "auth/invalid-email":
          return commit(
            "setError",
            "Die E-Mail-Adresse ist schlecht formatiert."
          );
        case "auth/wrong-password":
          return commit("setError", "Das Passwort ist ungültig.");
        case "auth/user-not-found":
          return commit(
            "setError",
            "Es gibt keinen Benutzer mit dieser E-Mail."
          );
        case "auth/user-mismatch":
          return commit(
            "setError",
            "Die angegebenen Anmeldeinformationen entsprechen nicht dem zuvor angemeldeten Benutzer."
          );
        case "auth/too-many-requests":
          return commit(
            "setError",
            "Der Zugriff auf dieses Konto wurde vorübergehend deaktiviert. Lege dein Passwort fest oder versuche es später erneut."
          );
        case "auth/account-exists-with-different-credential":
          return commit("setError", "Error Message");
        case "auth/network-request-failed":
          return commit("setError", "Keine Netzwerkverbindung");
        case "auth/requires-recent-login":
          return commit("setError", "Melde dich bitte erneut an");
        case "auth/user-disabled":
          return commit(
            "setError",
            "Dein Account wurde deaktiviert. Bitte nimm Kontakt mit Pangeapp auf."
          );
        default:
          return commit("setError", null);
      }
    },

    async signInAction({ commit, dispatch }, payload) {
      return await auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(response => {
          if (response.user.emailVerified) {
            console.log("sign in with Email and Password was successful");
            commit("setUser", response.user);
          } else {
            auth.languageCode = "de";
            auth.currentUser.sendEmailVerification();
            commit(
              "setError",
              "Die E-Mail-Adresse wurde nicht verifiziert. Es wurde erneut ein Link zur Verifizierung Deiner E-Mail-Adresse gesendet."
            );
            dispatch("signOutAction");
          }
        })
        .catch(error => {
          dispatch("errorMessageFromFirebase", error);
        });
    },

    signOutAction({ commit }) {
      auth
        .signOut()
        .then(() => {
          commit("setUser", null);
        })
        .catch(error => {
          commit("setError", error.message);
        });
    },

    async authAction({ commit }) {
      await auth.onAuthStateChanged(user => {
        if (user) {
          commit("setUser", user);
        } else {
          commit("setUser", null);
        }
      });
    },

    likeAction({ state }, payload) {
      let username = state.currentUser.displayName;
      let increment = firebase.firestore.FieldValue.increment(1);
      let decrement = firebase.firestore.FieldValue.increment(-1);
      if (!payload.isLikedBy) {
        db.collection("problems")
          .doc(payload.problemId)
          .update({ [`isLikedBy.${username}`]: true });
        db.collection("problems")
          .doc(payload.problemId)
          .update({ likes: increment });
        if (payload.dislikes > 0 && payload.isDislikedBy) {
          db.collection("problems")
            .doc(payload.problemId)
            .update({ [`isDislikedBy.${username}`]: false });
          db.collection("problems")
            .doc(payload.problemId)
            .update({ dislikes: decrement });
        }
      } else {
        db.collection("problems")
          .doc(payload.problemId)
          .update({ [`isLikedBy.${username}`]: false });
        db.collection("problems")
          .doc(payload.problemId)
          .update({ likes: decrement });
      }
    },

    dislikeAction({ state }, payload) {
      let username = state.currentUser.displayName;
      let increment = firebase.firestore.FieldValue.increment(1);
      let decrement = firebase.firestore.FieldValue.increment(-1);
      if (!payload.isDislikedBy) {
        db.collection("problems")
          .doc(payload.problemId)
          .update({ [`isDislikedBy.${username}`]: true });
        db.collection("problems")
          .doc(payload.problemId)
          .update({ dislikes: increment });
        if (payload.likes > 0 && payload.isLikedBy) {
          db.collection("problems")
            .doc(payload.problemId)
            .update({ [`isLikedBy.${username}`]: false });
          db.collection("problems")
            .doc(payload.problemId)
            .update({ likes: decrement });
        }
      } else {
        db.collection("problems")
          .doc(payload.problemId)
          .update({ [`isDislikedBy.${username}`]: false });
        db.collection("problems")
          .doc(payload.problemId)
          .update({ dislikes: decrement });
      }
    },

    /* eslint-disable no-unused-vars */
    incrementSolvedOnAttempt({ state }, payload) {
      let increment = firebase.firestore.FieldValue.increment(1);
      const ref = db.collection("problems").doc(payload.problemId);
      try {
        switch (payload.attemptBy) {
          case 1:
            ref.update({ ["solvedOnAttempt.first"]: increment });
            break;
          case 2:
            ref.update({ ["solvedOnAttempt.second"]: increment });
            break;
          case 3:
            ref.update({ ["solvedOnAttempt.third"]: increment });
            break;
          case 4:
            ref.update({ ["solvedOnAttempt.fourth"]: increment });
            break;
          case 5:
            ref.update({ ["solvedOnAttempt.fifth"]: increment });
            break;
        }
      } catch {
        console.log("error");
      }
    },

    async sendEmailAction({ state }, payload) {
      await db.collection("mails").add({
        subject: payload.subject,
        from: payload.email,
        message: payload.message,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    },

    updateData({ state }, payload) {
      let increment = firebase.firestore.FieldValue.increment(1);
      if (payload.topic) {
        db.collection("statistics")
          .doc("topics")
          .update({ total: increment });
        db.collection("statistics")
          .doc("topics")
          .update({ [`${payload.topic}`]: increment });
      }
    }
    /* eslint-enable no-unused-vars */
  },
  getters: {
    getProblemsByPointsAndGrades: state => (points, grade) => {
      return state.problems.filter(problem => {
        return problem.points === points && problem.grades.includes(grade);
      });
    },
    getProblemsByGrade: state => grade => {
      return state.problems.filter(problem => {
        return problem.grades.includes(grade);
      });
    },
    getStudentsByGrade: state => grade => {
      return state.students.filter(student => student.grade === grade);
    }
    // getCurrentUserDisplayName: (state) => state.currentUser.displayName,
    // getCurrentUserEmail: (state) => state.currentUser.email,
    // setTimeout(() => {getActiveUserSchool: (state) => state.activeUser.school,
    // getActiveUserGender: (state) => state.activeUser.gender,
    // getActiveUserGrade: (state) => state.activeUser.grade,
    // getActiveUserFederalState: (state) => state.activeUser.federalState,
    // getActiveUserUserType: (state) => state.activeUser.userType
  }
});
