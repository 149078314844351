<template>
  <div
    id="gender-data"
    class="flex flex-col m-0 m-auto justify-center w-full mb-10 sm:w-1/2"
    v-if="statistics"
  >
    <div class="font-bold text-xs text-center">Geschlecht</div>
    <GChart type="PieChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        pieHole: 0,
        colors: ["#61B231", "#96CC76", "#CAE5BA"],
        is3D: true
      }
    };
  },
  methods: {
    ...mapActions(["bindStatistics"])
  },
  mounted() {
    this.bindStatistics();
  },
  computed: {
    ...mapState(["statistics"]),
    statisticsAsObject() {
      const arrayToObject = array =>
        array.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      if (this.statistics) {
        return arrayToObject(this.statistics);
      }
      return null;
    },
    chartData() {
      let genderArray = [
        ["Geschlecht", "Anzahl"],
        ["weiblich", this.statisticsAsObject.gender.female],
        ["männlich", this.statisticsAsObject.gender.male],
        ["divers", this.statisticsAsObject.gender.diverse]
      ];
      return genderArray;
    }
  }
};
</script>

<style></style>
