<template>
  <div id="table-of-contents">
    <div class="flex flex-col pl-4 font-semibold">
      <div v-for="(title, refName) in titles" :key="refName">
        <a class="cursor-pointer hover:underline" @click="scrollMeTo(refName)"
          ><span class="text-pangea-green">#</span> {{ title }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableOfContents",
  props: {
    titles: Object,
    refName: String
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$parent.$refs[refName];
      element.scrollIntoView();
    }
  }
};
</script>

<style></style>
