<template>
  <div id="random-problem" v-if="currentUser">
    <FormulateForm
      #default="{ hasErrors }"
      class="m-0 m-auto sm:w-1/2 md:w-1/3 lg:w-1/4"
    >
      <FormulateInput
        type="select"
        name="Jahrgang"
        :options="{
          '3': 'Jahrgang 3',
          '4': 'Jahrgang 4',
          '5': 'Jahrgang 5',
          '6': 'Jahrgang 6',
          '7': 'Jahrgang 7',
          '8': 'Jahrgang 8',
          '9': 'Jahrgang 9',
          '10': 'Jahrgang 10'
        }"
        placeholder="Jahrgang auswählen..."
        v-model="grade"
        validation="required"
        validation-behavior="live"
      />
      <FormulateInput
        type="submit"
        value="Neue Aufgabe"
        @click="createProblem(grade)"
        :disabled="hasErrors"
      />
    </FormulateForm>
    <div v-for="(problem, index) in problems" :key="index" class="mb-10">
      <Problem
        :key="index"
        :attemptBy="problem.attemptBy[currentUser.displayName]"
        :choices="problem.choices"
        :problemId="problem.id"
        :likes="problem.likes"
        :dislikes="problem.dislikes"
        :isLikedBy="problem.isLikedBy[currentUser.displayName]"
        :isDislikedBy="problem.isDislikedBy[currentUser.displayName]"
        :isSolvedBy="problem.isSolvedBy[currentUser.displayName]"
        :points="problem.points"
        :textFirst="problem.text"
        :textSecond="problem.secondText"
        :textThird="problem.thirdText"
        :svg="problem.svg"
        :anotherSvg="problem.anotherSvg"
      />
    </div>
    <div v-if="randomProblem">
      {{ currentUser.displayName }}
      {{ randomProblem[0].isLikedBy[currentUser.displayName] }}
      {{ randomProblem[0].attemptBy[currentUser.displayName] }}
      <Problem
        :key="randomProblem"
        :attemptBy="randomProblem[0].attemptBy[currentUser.displayName]"
        :choices="randomProblem[0].choices"
        :problemId="randomProblem[0].id"
        :likes="randomProblem[0].likes"
        :dislikes="randomProblem[0].dislikes"
        :isLikedBy="randomProblem[0].isLikedBy[currentUser.displayName]"
        :isDislikedBy="randomProblem[0].isDislikedBy[currentUser.displayName]"
        :isSolvedBy="randomProblem[0].isSolvedBy[currentUser.displayName]"
        :points="randomProblem[0].points"
        :textFirst="randomProblem[0].text"
        :textSecond="randomProblem[0].secondText"
        :textThird="randomProblem[0].thirdText"
        :svg="randomProblem[0].svg"
        :anotherSvg="randomProblem[0].anotherSvg"
      />
    </div>
  </div>
</template>

<script>
import Problem from "@/components/Problem.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    Problem
  },
  data() {
    return {
      grade: "",
      randomProblem: null
    };
  },
  computed: {
    ...mapState(["currentUser", "problems"]),
    ...mapGetters(["getProblemsByGrade"])
  },
  methods: {
    ...mapActions(["bindProblems", "unbindProblems"]),
    createProblem(grade) {
      if (this.$emit("").$children[1]) {
        this.$emit("").$children[1].feedback.text = "";
        this.$emit("").$children[1].pickedAnswer = "";
      }
      if (grade) {
        this.bindProblems({
          keys: ["grade"],
          values: [grade]
        });
      }
      setTimeout(() => {
        if (this.problems.length === 0) {
          this.createProblem(grade);
        }
      }, 500);
    }
    // createRandomProblem() {
    //   if (this.$emit("").$children[1]) {
    //     console.log(this.$emit("").$children[1].feedback.text);
    //     this.$emit("").$children[1].feedback.text = "";
    //     this.$emit("").$children[1].pickedAnswer = "";
    //   }
    //   // Declare Problems
    //   const problems = this.getProblemsByGrade(this.grade);
    //   // Declare two empty array
    //   let newArray = [];
    //   let copyOfProblems = [];

    //   // Copy problems to the array copyOfProblems
    //   problems.forEach(element => {
    //     copyOfProblems.push(element);
    //   });
    //   // Add to the array newArray the element at the n-index of copyOfProblems
    //   // Choose a random index
    //   let index = Math.floor(Math.random() * copyOfProblems.length);
    //   newArray.push(copyOfProblems[index]);
    //   copyOfProblems.splice(index, 1);
    //   this.randomProblem = newArray;
    //   return newArray;
    // }
  },
  destroyed() {
    this.unbindProblems();
  }
};
</script>

<style></style>
