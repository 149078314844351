<template>
  <div class="w-4/5 sm:w-2/5 md:w-1/3 lg:w-1/4">
    <TheTitle title="Passwort zurücksetzen" class="text-center" />
    <FormulateForm #default="{hasErrors}">
      <FormulateInput
        v-model="email"
        name="Email"
        type="email"
        placeholder="E-Mail"
        validation="required"
      />

      <FormulateInput
        type="button"
        @click.prevent="resetPassword"
        :disabled="hasErrors"
      >
        Passwort zurücksetzen
      </FormulateInput>
    </FormulateForm>
    <div class="text-center ">
      <router-link to="/signin" class="hover:underline">
        zurück zur Anmeldeseite
      </router-link>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase.js";
import { mapActions, mapState } from "vuex";
import TheTitle from "@/components/TheTitle.vue";

export default {
  components: {
    TheTitle
  },
  data() {
    return {
      email: "",
      successMessage:
        "Der Link zum Zurücksetzen des Passworts wurde an deine E-Mail-Adresse gesendet. Der Link führt Dich zur Seite, wo Du ein neues Passwort festlegen kannst."
    };
  },
  computed: {
    ...mapState(["error"])
  },
  methods: {
    ...mapActions(["errorMessageFromFirebase"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.go();
      }, 3000);
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/signin");
      }, 5000);
    },
    resetPassword() {
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.successNotification();
        })
        .catch(error => {
          this.errorMessageFromFirebase(error);
          this.errorNotification();
        });
    }
  }
};
</script>

<style></style>
