<template>
  <div class="p-2">
    <FormulateInput
      :target="target"
      @click="routeToSignUpOrInPage"
      :input-class="
        `rounded-full px-3 py-2 bg-${backgroundColor} font-semibold w-44 text-xl focus:outline-none text-${textColor} shadow-lg transform hover:scale-105`
      "
      type="button"
      :value="value"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    value: String,
    textColor: String,
    target: String,
    isDisabled: Boolean
  },
  methods: {
    routeToSignUpOrInPage() {
      this.$router.push(`/${this.target}`);
    }
  }
};
</script>

<style></style>
