<template>
  <div id="challenge" class="relative">
    <TheTitle title="Challenge" class="text-center" />
    <em>
      Diese Seite wurde aufgrund eines Problems vorübergehend deaktiviert. An
      der Behebung des Problems wird gearbeitet. Wir bitten um Verständnis.</em
    >
    <!--
    <FormulateForm
      v-if="!challengeStarted"
      #default="{ hasErrors }"
      style="margin: 0 auto; max-width: 300px"
    >
      <FormulateInput
        label="Zeit in min "
        type="range"
        name="time"
        min="25"
        max="60"
        step="5"
        show-value="true"
        v-model="minutes"
        validation="required"
      />
       <FormulateInput
                label="Aufgabenanzahl"
                type="range"
                name="problems"
                min="2"
                max="4"
                v-model="numberOfProblems"
                validation="required"
                error-behavior="live"
            /> {{numberOfProblems}} Aufgaben
            <br />
            <br />
      <FormulateInput
        placeholder="Modus"
        type="select"
        name="Modus"
        :options="{
          preliminary: 'Vorrunde',
          intermediate: 'Zwischenrunde',
          final: 'Finalrunde'
        }"
        v-model="mode"
        validation="required"
      />
      <FormulateInput
        type="select"
        placeholder="Jahrgang"
        name="Jahrgang"
        :options="{
          '3': 'Jahrgang 3',
          '4': 'Jahrgang 4',
          '5': 'Jahrgang 5',
          '6': 'Jahrgang 6',
          '7': 'Jahrgang 7',
          '8': 'Jahrgang 8',
          '9': 'Jahrgang 9',
          '10': 'Jahrgang 10'
        }"
        v-model="grade"
        validation="required"
      />
      <br />
      <FormulateInput
        type="button"
        label="Challenge starten"
        @click="startChallenge(mode, grade)"
        :disabled="hasErrors"
      />
    </FormulateForm>
    -->
    <div
      v-if="
        mode === 'preliminary' &&
          windowWidth < 640 &&
          challengeStarted &&
          problems
      "
      id="table-preliminary"
      class="bg-white sticky top-12 left-0 right-0 z-20 p-4"
    >
      <table class="table-fixed border m-0 m-auto max-w-full">
        <thead>
          <tr class="pt-4 break-words">
            <th
              class="w-8 text-center border"
              v-for="taskNumber in range(1, numberOfProblems - 10)"
              :key="taskNumber * -1"
            >
              {{ taskNumber }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              :class="
                'w-4 text-center border bg-' +
                  textColor[taskNumber - 1] +
                  '-500'
              "
              v-for="taskNumber in range(1, numberOfProblems - 10)"
              :key="taskNumber * -1"
            >
              <span v-if="pickedAnswers[taskNumber - 1]">{{
                pickedAnswers[taskNumber - 1].slice(0, 2)
              }}</span>
              <span v-else class="text-white">.</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-fixed border m-0 m-auto max-w-full">
        <thead>
          <tr class="pt-4 break-words">
            <th
              class="w-8 text-center border"
              v-for="taskNumber in range(
                numberOfProblems - 9,
                numberOfProblems
              )"
              :key="taskNumber * -1"
            >
              {{ taskNumber }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              :class="
                'w-4 text-center border bg-' +
                  textColor[taskNumber - 1] +
                  '-500'
              "
              v-for="taskNumber in range(
                numberOfProblems - 9,
                numberOfProblems
              )"
              :key="taskNumber * -1"
            >
              <span v-if="pickedAnswers[taskNumber - 1]">{{
                pickedAnswers[taskNumber - 1].slice(0, 2)
              }}</span>
              <span v-else class="text-white">.</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      id="table-intermediate-final-window-width-less-640"
      v-else-if="windowWidth < 640 && challengeStarted && problems"
      class="w-full bg-white sticky top-12 z-30 p-4"
    >
      <table class="table-fixed border m-0 m-auto max-w-full">
        <thead>
          <tr class="pt-4 break-words">
            <th
              class="w-8 text-center border"
              v-for="taskNumber in numberOfProblems"
              :key="taskNumber * -1"
            >
              {{ taskNumber }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              :class="
                'w-4 text-center border bg-' +
                  textColor[taskNumber - 1] +
                  '-500'
              "
              v-for="taskNumber in numberOfProblems"
              :key="taskNumber * -1"
            >
              <span v-if="pickedAnswers[taskNumber - 1]">{{
                pickedAnswers[taskNumber - 1].slice(0, 2)
              }}</span>
              <span v-else class="text-white">.</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      id="table-intermediate-final"
      v-else
      class="w-full bg-white sticky top-28 z-30 p-4"
    >
      <table class="table-fixed border m-0 m-auto max-w-full">
        <thead>
          <tr class="pt-4 break-words">
            <th
              class="w-8 text-center border"
              v-for="taskNumber in numberOfProblems"
              :key="taskNumber * -1"
            >
              {{ taskNumber }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              :class="
                'w-4 text-center border bg-' +
                  textColor[taskNumber - 1] +
                  '-500'
              "
              v-for="taskNumber in numberOfProblems"
              :key="taskNumber * -1"
            >
              <span v-if="pickedAnswers[taskNumber - 1]">{{
                pickedAnswers[taskNumber - 1].slice(0, 2)
              }}</span>
              <span v-else class="text-white">.</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="!problems && challengeStarted"
      class="flex flex-col items-center mt-10"
    >
      <button type="button" class="flex italic" disabled>
        <svg
          class="animate-spin h-5 w-5 mr-3 border bg-gray-200 rounded"
          viewBox="0 0 24 24"
        >
          <!-- ... -->
        </svg>
        Aufgaben werden geladen
      </button>
    </div>
    <div v-else>
      <div
        v-for="(problem, index) in problems"
        :key="index + problem"
        id="problem"
        class="m-0 m-auto shadow-md rounded-lg p-4 mb-10 text-lg lg:w-4/5"
      >
        <div id="problem-wrapper" class="flex flex-col">
          <div id="problem-header" class="grid grid-cols-2 border-b mb-3">
            <div class="font-black text-lg">Aufgabe {{ index + 1 }}</div>
            <div class="font-medium text-right">
              {{ problem.points }} <span v-if="points === 1"> Punkt</span>
              <span v-else>Punkte</span>
            </div>
          </div>
          <div id="problem-text-wrapper" class="mb-3">
            <div
              id="problem-text-first"
              class="list-disc"
              v-katex:auto
              v-html="problem.text"
            ></div>
            <div id="problem-shape-first" class="my-2">
              <img
                v-if="problem.svg != ``"
                :src="require(`../assets/svg/${problem.svg}.svg`)"
                class="m-0 m-auto max-w-full max-h-full"
              />
            </div>
            <div v-if="problem.secondText != ``" id="problem-text-first">
              {{ problem.secondText }}
            </div>
            <div
              v-if="!!problem.anotherSvg"
              id="problem-shape-first"
              class="text-center"
            >
              <img
                :src="require(`../assets/svg/${problem.anotherSvg}.svg`)"
                class="max-w-full max-h-full m-0 m-auto"
              />
            </div>
            <div v-if="problem.thirdText != ``" id="problem-text-third">
              {{ problem.thirdText }}
            </div>
          </div>
          <div
            id="problem-choices"
            class="flex flex-col sm:flex-row flex-wrap justify-between"
          >
            <div
              v-for="(choice, ind) in problem.choices"
              :key="choice"
              :id="`problem-choice-${ind + 1}`"
              class="pr-5 mb-4 flex items-center"
            >
              <input
                type="radio"
                :id="choice"
                :value="choice"
                v-model="pickedAnswers[index]"
                class="h-4 w-4"
                :disabled="disabled"
              />
              <label :for="choice" class="pl-1 break-word" v-katex:auto>{{
                choice
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormulateInput
      v-show="challengeStarted && problems"
      type="submit"
      value="Challenge beenden"
      @click="submitChallenge"
      :disabled="challengeStopped"
    />
    <div
      id="feedback"
      class="flex justify-center my-24"
      v-show="challengeStopped"
    >
      <table class="table-fixed text-2xl">
        <tbody>
          <tr class="text-purple-500">
            <th class="text-left border-b w-36">Bearbeitungszeit</th>
            <td class="text-center border-b w-20" v-if="pastSeconds % 60 >= 10">
              {{ pastMinutes }}:{{ pastSeconds % 60 }}
            </td>
            <td class="text-center border-b w-20" v-else>
              {{ pastMinutes }}:0{{ pastSeconds % 60 }}
            </td>
          </tr>
          <tr class="text-blue-500">
            <th class="text-left border-b">Aufgaben</th>
            <td class="text-center border-b w-20">{{ numberOfProblems }}</td>
          </tr>
          <tr class="text-green-500">
            <th class="text-left border-b">Richtig</th>
            <td class="text-center border-b w-20">
              {{ numberOfRightAnswers }}
            </td>
          </tr>
          <tr class="text-red-500">
            <th class="text-left border-b">Falsch</th>
            <td class="text-center border-b w-20">
              {{ numberOfFalseAnswers }}
            </td>
          </tr>
          <tr class="text-yellow-500">
            <th class="text-left border-b">Unbeantwortet</th>
            <td class="text-center border-b w-20">
              {{ numberOfUnAnsweredProblems }}
            </td>
          </tr>
          <tr>
            <th class="text-left border-b">Gesamtpunktzahl</th>
            <td class="text-center w-20">{{ totalPoints }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <FormulateInput
      v-show="challengeStopped && problems"
      type="submit"
      value="Challenge neustarten"
      @click="restartChallenge"
    />
    <div
      id="timer"
      class="sticky bottom-0 text-right right-4 rounded-md bg-white z-30 text-3xl font-black"
    >
      <div class="text-red-600 text-2xl" v-if="minutes == 0 && seconds == 0">
        Die Zeit ist leider um. <span class="lnr lnr-sad"></span>
      </div>
      <div v-else-if="challengeStarted && problems">
        <span class="lnr lnr-clock text-2xl"></span> {{ countDown }}
      </div>
    </div>
  </div>
</template>

<script>
import TheTitle from "@/components/TheTitle.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TheTitle
  },
  data() {
    return {
      disabled: false,
      minutes: 45,
      seconds: 0,
      points: null,
      grade: "",
      mode: "",
      challengeStarted: false,
      challengeStopped: false,
      pastSeconds: 0,
      currentSeconds: 0,
      problems: null,
      pickedAnswers: {},
      numberOfRightAnswers: 0,
      numberOfFalseAnswers: 0,
      numberOfUnAnsweredProblems: 0,
      numberOfProblems: null,
      textColor: [],
      totalPoints: 0,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapGetters(["getProblemsByPointsAndGrades"]),
    countDown() {
      if (this.seconds < 10) {
        return `${this.minutes}:0${this.seconds}`;
      } else {
        return `${this.minutes}:${this.seconds}`;
      }
    },
    pastMinutes() {
      return Math.floor(this.currentSeconds / 60);
    }
  },
  methods: {
    ...mapActions(["bindProblems", "unbindProblems"]),
    range(min, max) {
      var array = [],
        j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    submitChallenge() {
      this.disabled = true;
      this.challengeStopped = true;
      this.pastSeconds = this.pastSeconds % 60;
      this.checkAnswers();
    },
    startChallenge() {
      this.challengeStarted = true;
      setTimeout(() => {
        let interval = setInterval(() => {
          if (this.seconds == 0) {
            this.minutes--;
          }
          this.seconds--;
          if (this.seconds < 0) {
            this.seconds += 60;
          }
          this.pastSeconds++;
          this.currentSeconds++;
          if (this.minutes === 0 && this.seconds === 0) {
            clearInterval(interval);
          }
          if (this.challengeStopped) {
            clearInterval(interval);
          }
        }, 1000);
        this.randomProblems(this.mode, parseInt(this.grade));
      }, 1000);
    },
    async randomProblems(mode, grade) {
      setTimeout(() => {
        if (mode === "preliminary" && grade >= 7) {
          const A = this.createRandomProblems(1, 3);
          const B = this.createRandomProblems(2, 3);
          const C = this.createRandomProblems(3, 4);
          const D = this.createRandomProblems(4, 5);
          const E = this.createRandomProblems(5, 5);
          this.problems = A.concat(B)
            .concat(C)
            .concat(D)
            .concat(E);
          this.numberOfProblems = 20;

          return this.problems;
        }
        if (mode === "preliminary" && (grade === 5 || grade === 6)) {
          const A = this.createRandomProblems(1, 3);
          const B = this.createRandomProblems(2, 3);
          const C = this.createRandomProblems(3, 3);
          const D = this.createRandomProblems(4, 4);
          const E = this.createRandomProblems(5, 5);
          this.problems = A.concat(B)
            .concat(C)
            .concat(D)
            .concat(E);
          this.numberOfProblems = 18;

          return this.problems;
        }
        if (mode === "preliminary" && (grade === 3 || grade === 4)) {
          const A = this.createRandomProblems(1, 3);
          const B = this.createRandomProblems(2, 2);
          const C = this.createRandomProblems(3, 3);
          const D = this.createRandomProblems(4, 2);
          const E = this.createRandomProblems(5, 5);
          this.problems = A.concat(B)
            .concat(C)
            .concat(D)
            .concat(E);
          this.numberOfProblems = 15;

          return this.problems;
        }

        if (mode === "intermediate" && grade <= 4) {
          const C = this.createRandomProblems(3, 2);
          const D = this.createRandomProblems(4, 3);
          const E = this.createRandomProblems(5, 5);
          this.problems = C.concat(D).concat(E);
          this.numberOfProblems = 10;

          return this.problems;
        }

        if (mode === "intermediate" && grade >= 5) {
          const C = this.createRandomProblems(3, 3);
          const D = this.createRandomProblems(4, 4);
          const E = this.createRandomProblems(5, 5);
          this.problems = C.concat(D).concat(E);
          this.numberOfProblems = 12;

          return this.problems;
        }
        if (mode === "final" && grade <= 4) {
          const E = this.createRandomProblems(5, 5);
          this.problems = E;
          this.numberOfProblems = 5;

          return this.problems;
        }
        if (mode === "final" && grade >= 5) {
          const E = this.createRandomProblems(5, 7);
          this.problems = E;
          this.numberOfProblems = 7;

          return this.problems;
        }
      }, 2000);
    },
    createRandomProblems(points, numberOfProblems) {
      // Declare Problems
      const problems = this.getProblemsByPointsAndGrades(points, this.grade);
      // Declare two empty array
      let newArray = [];
      let copyOfProblems = [];

      // Copy problems to the array copyOfProblems
      problems.forEach(element => {
        copyOfProblems.push(element);
      });
      // Add to the array newArray the element at the n-index of copyOfProblems
      for (let i = 0; i < numberOfProblems; i++) {
        // Choose a random index
        let index = Math.floor(Math.random() * copyOfProblems.length);
        newArray.push(copyOfProblems[index]);
        copyOfProblems.splice(index, 1);
      }

      return newArray;
    },

    checkAnswers() {
      for (let index in this.problems) {
        if (this.pickedAnswers[index] === this.problems[index].solution) {
          this.textColor[index] = "green";
          this.numberOfRightAnswers++;
          this.totalPoints += this.problems[index].points;
        } else if (!this.pickedAnswers[index]) {
          this.textColor[index] = "yellow";
        } else {
          this.textColor[index] = "red";
          this.numberOfFalseAnswers++;
        }
      }
      this.numberOfUnAnsweredProblems =
        this.numberOfProblems -
        this.numberOfRightAnswers -
        this.numberOfFalseAnswers;
    },
    restartChallenge() {
      this.challengeStarted = false;
      this.problems = null;
      this.pickedAnswers = {};
      this.textColor = [];
      this.mode = "";
      this.disabled = false;
      this.numberOfProblems = null;
      this.challengeStopped = false;
      this.grade = "";
      this.minutes = 45;
      this.seconds = 0;
      this.pastSeconds = 0;
      this.currentSeconds = 0;
      this.numberOfRightAnswers = 0;
      this.numberOfFalseAnswers = 0;
      this.numberOfUnAnsweredProblems = 0;
      this.totalPoints = 0;
    }
  },
  destroyed() {
    this.unbindProblems();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.bindProblems();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style>
/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: solid 1pt black;
  background: black;
  cursor: pointer;
  margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: solid 1pt black;
  background: black;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: solid 1pt black;
  background: black;
  cursor: pointer;
}

#timer {
  position: -webkit-fixed;
}

#table-preliminary {
  position: -webkit-sticky;
}

#table-intermediate-final {
  position: -webkit-sticky;
}

#table-intermediate-final-window-width-less-640 {
  position: -webkit-sticky;
}

#challenge td {
  border-bottom: 1px solid #e5e7eb;
}
</style>
