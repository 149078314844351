<template>
  <div id="short-user-info">
    <div v-if="currentUser && activeUser" class="px-1 text-center text-lg">
      {{ activeUser.nickname }}
      <br />
      <span v-if="activeUser.points === 1" class="text-sm"
        >{{ activeUser.points }} Punkt</span
      >
      <span v-else class="text-sm">{{ activeUser.points }} Punkte</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ShortUserInfo",
  computed: {
    ...mapState(["currentUser", "activeUser"])
  },
  methods: {
    ...mapActions(["signOutAction", "bindActiveUser", "authAction"])
  },
  mounted() {
    this.bindActiveUser();
  }
};
</script>

<style></style>
