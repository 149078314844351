<template>
  <div
    class="m-auto mb-12 font-medium border rounded-tr-2xl rounded-bl-2xl p-4 shadow sm:w-3/4"
  >
    <p class="italic">
      <q lang="de">{{ quotes[index].quote }}</q>
    </p>
    <div class="text-right" style="font-variant: small-caps;">
      {{ quotes[index].author }}
      <a
        :href="`https://de.wikipedia.org/wiki/${authorSnakeCase}`"
        target="_blank"
        class="relative pointer border border-black rounded-full hover:border-pangea-green hover:text-pangea-green"
        style="font-variant: normal;"
      >
        <span style="padding: 0 9px 0 9px;">i</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quotes: [
        {
          quote:
            "Das entscheidende Kriterium ist Schönheit; für hässliche Mathematik ist auf dieser Welt kein beständiger Platz.",
          author: "Godfrey Harold Hardy"
        },
        {
          quote:
            "Die Mathematik als Fachgebiet ist so ernst, dass man keine Gelegenheit versäumen sollte, dieses Fachgebiet unterhaltsamer zu gestalten.",
          author: "Blaise Pascal"
        },
        {
          quote:
            "Wenn ich nach einem tausendjährigen Schlaf aufwachen würde, wäre meine erste Frage: Wurde die Riemann-Hypothese bewiesen?",
          author: "David Hilbert"
        },
        {
          quote:
            "Die Mathematik ist die Königin der Wissenschaften und die Zahlentheorie ist die Königin der Mathematik.",
          author: "Carl Friedrich Gauß"
        },
        {
          quote:
            "In der Welt geschieht nichts, worin man nicht den Sinn eines bestimmten Maximums oder Minimums erkennen könnte.",
          author: "Leonhard Euler"
        },
        {
          quote:
            "Die Mathematiker studieren nicht Objekte, sondern Beziehungen zwischen den Objekten; es kommt ihnen deshalb nicht darauf an, diese Objekte durch andere zu ersetzen, wenn dabei nur die Beziehungen ungeändert bleiben. Der Gegenstand ist für sie gleichgültig, die Form allein hat ihr Interesse.",
          author: "Henri Poincaré"
        },
        {
          quote:
            "Mach' dir keine Sorgen wegen deiner Schwierigkeiten mit der Mathematik. Ich kann dir versichern, dass meine noch größer sind.",
          author: "Albert Einstein"
        },
        {
          quote:
            "Alle Pädagogen sind sich darin einig: man muss vor allem tüchtig Mathematik treiben, weil ihre Kenntnis fürs Leben größten direkten Nutzen gewährt.",
          author: "Felix Klein"
        },
        {
          quote:
            "Meine (algebraischen) Methoden sind wirklich Methoden des Arbeitens und Denkens; deshalb haben sie sich überall anonym eingeschlichen.",
          author: "Emmy Noether"
        },
        {
          quote:
            "Um glücklich zu sein, muss man seine Vorurteile abgelegt und seine Illusionen behalten haben.",
          author: "Émilie du Châtelet"
        },
        {
          quote:
            "Liebe zum Lernen ist die wichtigste Leidenschaft… darin liegt unser Glück. Es ist ein sicheres Mittel gegen das, was uns quält, eine unendliche Quelle der Freude.",
          author: "Émilie du Châtelet"
        },
        {
          quote:
            "In Wirklichkeit ist sie (die Mathematik) aber eine Wissenschaft, die die größte Phantasie verlangt.",
          author: "Sofja Wassiljewna Kowalewskaja"
        },
        {
          quote:
            "Es ist von unschätzbarem Wert, einen Freund zu haben, der deine Interessen teilt und dir hilft, motiviert zu bleiben.",
          author: "Maryam Mirzakhani"
        },
        {
          quote:
            "Verteidige dein Recht zu denken. Denken und sich zu irren ist besser, als nicht zu denken.",
          author: "Hypatia"
        }
      ]
    };
  },
  computed: {
    index() {
      return Math.floor(Math.random() * this.quotes.length);
    },
    authorSnakeCase() {
      return this.quotes[this.index].author.replace(/ /g, "_");
    }
  }
};
</script>
