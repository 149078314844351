import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "./icons.js";
import VTooltip from "v-tooltip";
import "./firebase.js";
import "./vueformulate.js";
import "./vuekatex.js";
import { auth } from "./firebase.js";
import "./vuenotification.js";
import VueGoogleCharts from "vue-google-charts";

Vue.use(VueGoogleCharts);
Vue.use(VTooltip);

Vue.config.productionTip = false;

let app = "";
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});
