<template>
  <div id="home" class="text-lg flex flex-col justify-evenly sm:text-2xl">
    <div v-if="!currentUser">
      <div id="greeting" class="font-medium" v-katex:auto>
        Herzlich Willkommen auf der Pangea<span class="italic">pp</span>.
        <br />
        <br />
        Die Pangea<span class="italic">pp</span> ist eine kostenlose
        Webanwendung, womit sich Schüler*innen auf den
        Pangea-Mathematikwettbewerb gezielt vorbereiten können.
        <br />
        <br />
        Der Aufgaben-Pool wird stetig mit den Aufgaben aus den vergangenen
        Jahren gefüllt.
        <br />
        <br />
        Nachdem du dich registriert hast, kannst du dich anmelden und
        Pangea<span class="italic">pp</span>
        voll und ganz nutzen.
        <br />
        <br />
        Tauche mit Pangea<span class="italic">pp</span> in die bezaubernde Welt
        der Mathematik ein und löse hunderte von Aufgaben.
        <br />
        <br />
        Viel Spaß wünscht dir dein Pangea Team.
      </div>
      <div
        id="signup-signin-buttons"
        class="flex flex-col items-center align-center m-10"
      >
        <RouteButton
          :target="'signup'"
          :backgroundColor="'pangea-yellow'"
          :value="'Registrieren'"
          :textColor="'yellow-50'"
        />
        <RouteButton
          :target="'signin'"
          :backgroundColor="'pangea-green'"
          :value="'Anmelden'"
          :textColor="'yellow-50'"
        />
      </div>
    </div>
    <div v-else>
      Hallo <span class="italic">{{ currentUser.displayName }}</span
      >,
      <br />
      <br />
      wir begrüßen dich herzlich auf der Webanwendung
      <span class="italic">Pangeapp</span>.
      <br />
      <br />
      Über den Reiter
      <router-link to="/problems">
        <span class="bg-yellow-50"
          ><span class="lnr lnr-book"></span> Aufgaben</span
        >
      </router-link>
      gelangst du zu den Aufgaben. Dort kannst du Aufgaben nach Jahrgang,
      Schwierigkeitsgrad und Themengebiet filtern, und ohne Zeitdruck die
      Aufgaben in Ruhe lösen.
      <br />
      <br />
      Möchtest du die Wettbewerbssituation simulieren, so klicke auf den Reiter
      <router-link to="/challenge">
        <span class="bg-yellow-50"
          ><span class="lnr lnr-hourglass"></span> Challenge</span
        ></router-link
      >. Dort kannst du einen Timer stellen und die Wettbewerbsrunde (Vor-,
      Zwischen- oder Finalrunde) auswählen. Je nach dem bekommst du eine
      bestimmte Anzahl an Aufgaben ausgegeben, die du dann in der eingestellten
      Zeit lösen kannst.
      <br />
      <br />
      Über den Reiter
      <router-link to="/problems">
        <span class="bg-yellow-50"
          ><span class="lnr lnr-dice"></span> Zufallsaufgabe</span
        >
      </router-link>
      kommst du auf eine Seite, wo du nur dein Jahrgang auswählen musst.
      Anschließend kannst du dir eine zufällige Aufgabe ausgeben lassen.
      <br />
      <br />
      Bist du an Statistiken interessiert? Dann klicke doch auf den Reiter
      <router-link to="/statistics">
        <span class="bg-yellow-50"
          ><span class="lnr lnr-chart-bars"></span> Statistiken</span
        >.
      </router-link>
      Dort findest du interessante Daten.
      <br />
      <br />
      Wir wünschen dir viel Spaß mit der Pangeapp.
      <br />
      <br />
      Dein Pangea Team
    </div>
  </div>
</template>

<script>
import RouteButton from "@/components/RouteButton.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    RouteButton
  },
  computed: {
    ...mapState(["currentUser"])
  }
};
</script>
