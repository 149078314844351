<template>
  <div
    id="federal-state-data"
    class="flex flex-col m-0 m-auto justify-center w-full mb-10 sm:w-1/2"
    v-if="statistics"
  >
    <div class="font-bold text-xs text-center">Bundesland</div>
    <GChart
      type="GeoChart"
      :settings="{
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyAF7IBnHQxWs8yThsuXAjQhK7WUzknZ8-E'
      }"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: "Verteilung der Nutzer nach Bundesland",
          subtitle: "Sales, Expenses, and Profit: 2014-2017"
        },
        region: "DE",
        resolution: "provinces",
        colors: ["#F1F8EC", "#61B231"],
        language: "de"
      }
    };
  },
  methods: {
    ...mapActions(["bindStatistics"])
  },
  mounted() {
    this.bindStatistics();
  },
  computed: {
    ...mapState(["statistics"]),
    statisticsAsObject() {
      const arrayToObject = array =>
        array.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      if (this.statistics) {
        return arrayToObject(this.statistics);
      }
      return null;
    },
    chartData() {
      let genderArray = [
        ["Bundesland", "Nutzer"],
        ["Berlin", this.statisticsAsObject.federalStates.Berlin],
        ["Hamburg", this.statisticsAsObject.federalStates.Hamburg],
        ["Bremen", this.statisticsAsObject.federalStates.Bremen],
        [
          "Nordrhein-Westfalen",
          this.statisticsAsObject.federalStates.NordrheinWestfalen
        ],
        ["Niedersachsen", this.statisticsAsObject.federalStates.Niedersachsen],
        [
          "Baden-Württemberg",
          this.statisticsAsObject.federalStates.BadenWuerttemberg
        ],
        ["Bayern", this.statisticsAsObject.federalStates.Bayern],
        ["Saarland", this.statisticsAsObject.federalStates.Saarland],
        [
          "Rheinland-Pfalz",
          this.statisticsAsObject.federalStates.RheinlandPfalz
        ],
        ["Sachsen", this.statisticsAsObject.federalStates.Sachsen],
        ["Sachsen-Anhalt", this.statisticsAsObject.federalStates.SachsenAnhalt],
        [
          "Schleswig-Holstein",
          this.statisticsAsObject.federalStates.SchleswigHolstein
        ],
        [
          "Mecklenburg-Vorpommern",
          this.statisticsAsObject.federalStates.MecklenburgVorpommern
        ],
        ["Thüringen", this.statisticsAsObject.federalStates.Thueringen],
        ["Hessen", this.statisticsAsObject.federalStates.Hessen],
        ["DE-BB", this.statisticsAsObject.federalStates.Brandenburg]
      ];
      return genderArray;
    }
  }
};
</script>

<style></style>
