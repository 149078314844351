<template>
  <div id="change-password" class="m-0 m-auto sm:max-w-sm">
    <TheTitle :title="'Passwort ändern'" />
    <FormulateForm
      #default="{hasErrors}"
      class="bg-gray-50 rounded px-5 pt-5 pb-1"
    >
      <FormulateInput
        v-model="email"
        name="Email"
        type="email"
        placeholder="E-Mail|email"
        validation="required"
      />
      <FormulateInput
        v-model="currentPassword"
        type="password"
        name="Aktuelles Passwort"
        placeholder="Aktuelles Passwort"
        validation="required"
      />
      <FormulateInput
        v-model="newPassword"
        type="password"
        name="NeuesPasswort"
        placeholder="Neues Passwort"
        :validation="[
          ['required'],
          ['min', 8],
          ['matches', /[0-9]/],
          ['matches', /[A-Z]/],
          ['matches', /[$&+,:;=?@#|/§'<>.^*()%!-]/]
        ]"
        validation-name="Neues Passwort"
        :validation-messages="{
          matches:
            'Passwort muss eine Zahl, einen Großbuchstaben und ein Sonderzeichen enthalten'
        }"
      />

      <FormulateInput
        v-model="repeatNewPassword"
        name="Neues Passwort wiederholen"
        type="password"
        placeholder="Neues Passwort wiederholen"
        validation="required|confirm:NeuesPasswort"
        :validation-messages="{
          confirm: 'Passwörter stimmen nicht überein.'
        }"
      />

      <FormulateInput
        type="button"
        @click.prevent="changePassword"
        :disabled="hasErrors"
        value="Passwort ändern"
      />
    </FormulateForm>
    <div class="text-center mt-5 text-sm">
      <router-link to="/userprofile" class="hover:underline"
        >zurück zur Profilseite</router-link
      >
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase.js";
import { mapActions, mapState } from "vuex";
import firebase from "firebase/app";
import TheTitle from "@/components/TheTitle.vue";

export default {
  components: {
    TheTitle
  },
  data() {
    return {
      email: "",
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      successMessage:
        "Dein Password wurde erfolgreich geändert. Du wirst nun zur Profilseite zurückgeleitet."
    };
  },
  computed: {
    ...mapState(["error"])
  },
  methods: {
    ...mapActions(["errorMessageFromFirebase"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.show = true;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/userprofile");
      }, 3000);
    },

    changePassword() {
      let credential = firebase.auth.EmailAuthProvider.credential(
        this.email,
        this.currentPassword
      );
      auth.currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          auth.currentUser.updatePassword(this.newPassword);
          this.successNotification();
        })
        .catch(error => {
          this.errorMessageFromFirebase(error);
          this.errorNotification();
        });
    }
  }
};
</script>

<style scoped>
.change-password-formular {
  position: relative;
}

.change-password-formular > input {
  border: solid 2px lightgray;
  border-radius: 11px;
  font-size: 12pt;
  margin-top: 5px;
  outline: none;
  padding: 25px 0 5px 10px;
  width: 250px;
}

.change-password-formular > input:focus {
  border: solid 2px #61b231;
}

label {
  position: absolute;
  font-size: 10pt;
  padding: 5px 5px 0px 10px;
  color: gray;
  margin-top: 3px;
}

.change-password-formular {
  padding: 5px 0px 5px 0px;
}

.change-password-formular > button {
  background: #61b231;
  border: solid 2px #61b231;
  border-radius: 25px;
  color: white;
  font-weight: bolder;
  margin: 5px 0 5px 0;
  outline: none;
  padding: 5px 0 5px 0;
  width: 100%;
}

.change-password-formular > button:hover {
  cursor: pointer;
  background: white;
  color: #61b231;
}

a {
  font-size: 10pt;
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
