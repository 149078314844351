<template>
  <div
    id="app"
    class="flex flex-col min-h-screen justify-between antialiased sm:subpixel-antialiased md:antialiased"
  >
    <div>
      <div
        id="header-navbar-wrapper"
        class="sticky bg-yellow-50 -top-20 z-40 pt-1 shadow-md rounded-b-2xl sm:top-0"
      >
        <TheHeader />
        <TheNavbar />
      </div>
      <router-view class="h-full m-auto my-14 sm:my-20 px-4 lg: max-w-5xl" />
    </div>
    <TheFooter />
    <TheNotification ref="notification" />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheNotification from "@/components/TheNotification.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    TheHeader,
    TheNavbar,
    TheFooter,
    TheNotification
  },
  computed: {
    ...mapState(["activeUser", "currentUser"])
  },
  methods: {
    ...mapActions(["authAction", "bindActiveUser"])
  },
  created() {
    this.authAction();
  },
  mounted() {
    this.bindActiveUser();
  }
};
</script>

<style>
span *,
::before,
::after {
  border-color: black;
}

ul,
li {
  list-style-type: disc;
  padding: unset;
  margin-left: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.katex-display > .katex > .katex-html {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */

#header-navbar-wrapper {
  position: -webkit-sticky;
  top: -5rem;
}

@media only screen and (min-width: 640px) {
  #header-navbar-wrapper {
    position: -webkit-sticky;
    top: 0;
  }
}
</style>
