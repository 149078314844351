<template>
  <div id="contact" class="sm:w-1/2">
    <TheTitle title="Kontakt" class="text-center" />
    <FormulateForm #default="{ hasErrors }">
      <FormulateInput
        v-model="email"
        type="email"
        label="E-Mail"
        validation="required|email"
        validation-name="E-Mail"
      />
      <FormulateInput
        v-model="subject"
        type="text"
        label="Betreff"
        validation="required"
        validation-name="Nachrichtentext"
      />
      <FormulateInput
        v-model="message"
        type="textarea"
        label="Nachricht"
        validation="required|max:10000"
        validation-name="Nachrichtentext"
        help="Das Eingabefeld kannst du vergrößern."
      />
      <vue-recaptcha
        v-if="!currentUser"
        class="recaptcha"
        ref="recaptcha"
        sitekey="6Le41lIaAAAAAMuDFvvR6vOWwduB5K9i_hxhmoeq"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
      >
      </vue-recaptcha>
      <br />
      <FormulateInput
        v-if="!currentUser"
        type="submit"
        @click="sendEmail"
        :disabled="hasErrors || disableSubmitButton"
      >
        Nachricht senden
      </FormulateInput>
      <FormulateInput
        v-else
        type="submit"
        @click="sendEmail"
        :disabled="hasErrors"
      >
        Nachricht senden
      </FormulateInput>
    </FormulateForm>

    <!-- <div
      style="background-color: #61B231; color: white; font-weight: bolder; font-size: 16pt;"
    >
      Wir haben dir eine Bestätigungsemail gesendet und werden uns um dein
      Anliegen schnellstmöglich kümmern. Du wirst nun zur Hauptseite
      weitergeleitet.
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import TheTitle from "@/components/TheTitle.vue";

export default {
  components: {
    VueRecaptcha,
    TheTitle
  },
  data() {
    return {
      message: "",
      email: "",
      subject: "",
      disableSubmitButton: true,
      showNotification: false,
      successMessage:
        "Deine Nachricht wurde erfolgreich an uns übermittelt. Wir werden uns schnellstmöglich um eine Rückmeldung bemühen."
    };
  },
  computed: {
    ...mapState(["error", "currentUser"])
  },
  methods: {
    ...mapActions(["sendEmailAction"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.go();
      }, 3000);
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/");
      }, 5000);
    },
    sendEmail() {
      this.sendEmailAction({
        subject: this.subject,
        message: this.message,
        email: this.email
      })
        .then(() => {
          this.successNotification();
        })
        .catch(error => {
          console.log(error);
          this.errorNotification();
        });
    },
    onCaptchaVerified() {
      this.disableSubmitButton = false;
    },
    onCaptchaExpired() {
      this.disableSubmitButton = true;
    }
  }
};
</script>

<style></style>
