<template>
  <!-- This page is only available for admin users -->
  <div class="add-new-problem">
    <TheTitle title="Neue Aufgabe hinzufügen" class="text-center" />
    <FormulateForm #default="{ hasErrors }">
      <FormulateInput
        v-model="text"
        type="textarea"
        placeholder="Aufgabentext"
        label="Aufgabe"
        validation="required"
        validation-name="Aufgabentext"
      />
      <div v-katex:auto :key="text" style="width: 400px;" v-html="text"></div>
      <br />

      <FormulateInput
        v-model="secondText"
        type="textarea"
        placeholder="Zusatztext"
        label="Zusatztext"
      />
      <div
        v-katex:auto
        :key="secondText"
        style="width: 400px;"
        v-html="secondText"
      ></div>
      <br />
      <FormulateInput
        v-model="thirdText"
        type="textarea"
        placeholder="Weiterer Zusatztext"
        label="Weiterer Zusatztext"
      />
      <div
        v-katex:auto
        :key="thirdText"
        style="width: 400px;"
        v-html="thirdText"
      ></div>
      <br />

      <FormulateInput type="group" name="choices" label="Distraktoren">
        <FormulateInput
          type="text"
          v-model="choiceA"
          placeholder="a)"
          validation="required"
          validation-name="Distraktor a)"
        />
        <div
          v-katex:auto
          :key="choiceA"
          style="width: 400px;"
          v-html="choiceA"
        ></div>
        <br />
        <FormulateInput
          type="text"
          v-model="choiceB"
          placeholder="b)"
          validation="required"
          validation-name="Distraktor b)"
        />
        <div
          v-katex:auto
          :key="choiceB"
          style="width: 400px;"
          v-html="choiceB"
        ></div>
        <br />
        <FormulateInput
          type="text"
          v-model="choiceC"
          placeholder="c)"
          validation="required"
          validation-name="Distraktor c)"
        />
        <div
          v-katex:auto
          :key="choiceC"
          style="width: 400px;"
          v-html="choiceC"
        ></div>
        <br />
        <FormulateInput
          type="text"
          v-model="choiceD"
          placeholder="d)"
          validation="required"
          validation-name="Distraktor d)"
        />
        <div
          v-katex:auto
          :key="choiceD"
          style="width: 400px;"
          v-html="choiceD"
        ></div>
        <br />
        <FormulateInput
          type="text"
          v-model="choiceE"
          placeholder="e)"
          validation="required"
          validation-name="Distraktor e)"
        />
        <div
          v-katex:auto
          :key="choiceE"
          style="width: 400px;"
          v-html="choiceE"
        ></div>
        <br />
      </FormulateInput>

      <FormulateInput
        type="text"
        v-model="solution"
        label="Antwort"
        validation="required"
      />

      <FormulateInput
        type="text"
        v-model="year"
        label="Erscheinungsjahr"
        validation="required"
      />

      <FormulateInput
        type="text"
        v-model="svg"
        label="SVG-Dateiname"
        help="Nur nötig, wenn zur Aufgabe ein Bild noch gehört."
      />

      <FormulateInput
        type="text"
        v-model="anotherSvg"
        label="Weiteres SVG-Dateiname"
        help="Nur nötig, wenn zur Aufgabe ein weiteres Bild noch gehört."
      />

      <FormulateInput
        type="select"
        :options="{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }"
        label="Punkte"
        placeholder="Wähle aus..."
        v-model="points"
        validation="required"
      />

      <FormulateInput
        type="checkbox"
        label="Für welche Jahrgänge ist die Aufgabe geeignet?"
        v-model="grades"
        :options="{
          '3': '3',
          '4': '4',
          '5': '5',
          '6': '6',
          '7': '7',
          '8': '8',
          '9': '9',
          '10': '10'
        }"
        validation="required"
      />
      <div>Geeignet für die Jahrgänge {{ grades }}</div>
      <br />

      <FormulateInput
        type="select"
        :options="{
          Algebra: 'Algebra',
          Arithmetik: 'Arithmetik',
          Funktionen: 'Funktionen',
          Geometrie: 'Geometrie',
          Kombinatorik: 'Kombinatorik',
          Logik: 'Logik',
          Wahrscheinlichkeit: 'Wahrscheinlichkeit',
          Zahlen: 'Zahlen'
        }"
        label="Themengebiet"
        placeholder="Wähle aus..."
        v-model="topic"
        validation="required"
      />

      <FormulateInput
        type="button"
        @click="
          submitProblem(
            text,
            secondText,
            thirdText,
            choiceA,
            choiceB,
            choiceC,
            choiceD,
            choiceE,
            solution,
            year,
            topic,
            grades,
            points,
            svg,
            anotherSvg
          )
        "
        :disabled="hasErrors"
      >
        Aufgabe hinzufügen
      </FormulateInput>
    </FormulateForm>
    <br />
    <br />
  </div>
</template>

<script>
import { db } from "@/firebase.js";
import { mapActions, mapState } from "vuex";
import TheTitle from "@/components/TheTitle.vue";

export default {
  name: "AddNewProblem",
  components: {
    TheTitle
  },
  data() {
    return {
      text: "",
      secondText: null,
      thirdText: null,
      choiceA: null,
      choiceB: null,
      choiceC: null,
      choiceD: null,
      choiceE: null,
      solution: null,
      year: "",
      topic: "",
      grades: [],
      points: null,
      svg: "",
      anotherSvg: null
    };
  },

  computed: {
    ...mapState(["nicknames"])
  },
  methods: {
    ...mapActions(["bindNicknames", "updateData"]),
    submitProblem(
      text,
      secondText,
      thirdText,
      choiceA,
      choiceB,
      choiceC,
      choiceD,
      choiceE,
      solution,
      year,
      topic,
      grades,
      points,
      svg,
      anotherSvg
    ) {
      let attemptBy = {};
      let isSolvedBy = {};
      let isDislikedBy = {};
      let isLikedBy = {};
      this.nicknames.forEach(user => {
        attemptBy[user.id] = 1;
        isSolvedBy[user.id] = false;
        isDislikedBy[user.id] = false;
        isLikedBy[user.id] = false;
      });

      const problem = {
        attemptBy: attemptBy,
        text: text,
        secondText: secondText,
        thirdText: thirdText,
        choices: [choiceA, choiceB, choiceC, choiceD, choiceE],
        likes: 0,
        dislikes: 0,
        solution: solution,
        year: year,
        grades: grades,
        points: parseInt(points),
        isDislikedBy: isDislikedBy,
        isLikedBy: isLikedBy,
        isSolvedBy: isSolvedBy,
        topic: topic,
        svg: svg,
        anotherSvg: anotherSvg
      };
      // Zahlen werden aktualisiert
      this.updateData({ topic: problem.topic });

      // Aufgabe wird in die Datenbank hinzugefügt
      db.collection("problems")
        .add(problem)
        .then(() => {
          setTimeout(() => {
            this.$router.go();
          }, 2000);
        });
    }
  },
  mounted() {
    this.bindNicknames();
  }
};
</script>
