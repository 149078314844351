<template>
  <div
    id="grade-data"
    class="flex flex-col m-0 m-auto justify-center w-full mb-10 sm:w-1/2"
    v-if="statistics"
  >
    <div class="font-bold text-xs text-center">Jahrgang</div>
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  data() {
    return {
      chartOptions: {
        legend: { position: "none" },
        axes: {
          y: {
            distance: { label: "parsecs" }, // Left y-axis.
            brightness: { side: "right", label: "apparent magnitude" } // Right y-axis.
          }
        },
        axisTitlesPosition: "out",
        hAxis: {
          title: "Jahrgänge"
        },
        vAxis: {
          title: "Anzahl der Schüler*innen"
        }
      }
    };
  },
  methods: {
    ...mapActions(["bindStatistics"])
  },
  mounted() {
    this.bindStatistics();
  },
  computed: {
    ...mapState(["statistics"]),
    statisticsAsObject() {
      const arrayToObject = array =>
        array.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      if (this.statistics) {
        return arrayToObject(this.statistics);
      }
      return null;
    },
    chartData() {
      let genderArray = [
        ["Jahrgang", "Schüler*innen", { role: "style" }],
        ["1", this.statisticsAsObject.grades["1"], "#89C565"],
        ["2", this.statisticsAsObject.grades["2"], "#89C565"],
        ["3", this.statisticsAsObject.grades["3"], "#89C565"],
        ["4", this.statisticsAsObject.grades["4"], "#89C565"],
        ["5", this.statisticsAsObject.grades["5"], "#89C565"],
        ["6", this.statisticsAsObject.grades["6"], "#89C565"],
        ["7", this.statisticsAsObject.grades["7"], "#89C565"],
        ["8", this.statisticsAsObject.grades["8"], "#89C565"],
        ["9", this.statisticsAsObject.grades["9"], "#89C565"],
        ["10", this.statisticsAsObject.grades["10"], "#89C565"],
        ["11+", this.statisticsAsObject.grades["11+"], "#89C565"]
      ];
      return genderArray;
    }
  }
};
</script>

<style></style>
