<template>
  <div id="navbar" class="flex justify-between">
    <div class="flex px-4 space-x-6 text-black text-xl py-2">
      <navbar-item :link="'/'" :itemTitle="'Home'" :iconName="'home'" />
      <navbar-item
        v-show="showNavbarItem"
        :link="'/problems'"
        :itemTitle="'Aufgaben'"
        :iconName="'book'"
      />
      <navbar-item
        v-show="showNavbarItem"
        :link="'/challenge'"
        :itemTitle="'Challenge'"
        :iconName="'hourglass'"
      />
      <navbar-item
        v-show="showNavbarItem"
        :link="'/randomproblem'"
        :itemTitle="'Zufallsaufgabe'"
        :iconName="'dice'"
      />
      <navbar-item
        v-show="showNavbarItem"
        :link="'/statistics'"
        :itemTitle="'Statistiken'"
        :iconName="'chart-bars'"
      />
    </div>
    <div class="flex space-x-6 mr-4 text-xl py-2">
      <navbar-item
        v-show="!showNavbarItem"
        :link="'/signin'"
        :itemTitle="'Anmelden'"
        :iconName="'enter'"
      />
      <navbar-item
        v-show="!showNavbarItem"
        :link="'/signup'"
        :itemTitle="'Registrieren'"
        :iconName="'plus-circle'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarItem from "../components/NavbarItem.vue";

export default {
  components: {
    NavbarItem
  },
  computed: {
    ...mapState(["currentUser"]),
    showNavbarItem() {
      if (this.currentUser) {
        if (this.currentUser.emailVerified) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
</script>

<style>
#navbar a.router-link-exact-active {
  color: #61b231;
}
</style>
