<template>
  <div
    id="problem"
    class="m-0 m-auto shadow-md rounded-lg p-4 mb-7 text-lg bg-pangea bg-left-top-10 bg-8 bg-origin-padding bg-no-repeat lg:w-4/5"
    :problemId="problemId"
    :problemNo="problemNo"
    :textFirst="textFirst"
    :isSolvedBy="isSolvedBy"
    :points="points"
    :attemptBy="attemptBy"
    :choices="choices"
    :key="textFirst"
    :svg="svg"
  >
    <div id="problem-wrapper" class="flex flex-col">
      <div
        id="problem-header"
        class="grid grid-cols-2 border-b mb-3 sm:grid-cols-4"
      >
        <div class="font-black text-lg">
          Aufgabe {{ problemNo }}
          <span
            v-if="isSolvedBy"
            class="lnr lnr-checkmark-circle text-pangea-green"
          ></span>
        </div>
        <div class="text-right sm:order-last">
          <LikeDislike
            :problemId="problemId"
            :likes="likes"
            :dislikes="dislikes"
            :isLikedBy="isLikedBy"
            :isDislikedBy="isDislikedBy"
          />
        </div>
        <div class="font-medium sm:text-center">
          {{ points }} <span v-if="points === 1"> Punkt</span>
          <span v-else>Punkte</span>
        </div>
        <div class="italic text-right sm:text-center">
          {{ attemptBy }}. Versuch
        </div>
      </div>
      <div id="problem-text-wrapper" class="mb-3">
        <div
          id="problem-text-first"
          class="list-disc"
          v-katex:auto
          v-html="textFirst"
        ></div>
        <div id="problem-shape-first" class="my-2">
          <img
            v-if="svg != ``"
            :src="require(`../assets/svg/${svg}.svg`)"
            class="m-0 m-auto max-w-full max-h-full"
          />
        </div>
        <div v-if="textSecond != ``" id="problem-text-first">
          {{ textSecond }}
        </div>
        <div v-if="!!anotherSvg" id="problem-shape-first" class="my-2">
          <img
            :src="require(`../assets/svg/${anotherSvg}.svg`)"
            class="m-0 m-auto max-w-full max-h-full"
          />
        </div>
        <div v-if="textThird != ``" id="problem-text-third">
          {{ textThird }}
        </div>
      </div>
      <div
        id="problem-choices"
        class="flex flex-col sm:flex-row flex-wrap justify-between"
      >
        <div
          v-for="(choice, index) in choices"
          :key="choice"
          :id="`problem-choice-${index + 1}`"
          class="pr-5 mb-4 flex items-center"
        >
          <input
            type="radio"
            :id="choice"
            :value="choice"
            v-model="pickedAnswer"
            class="h-4 w-4"
            @click="deleteCurrentFeedback"
          />
          <label
            :for="choice"
            class="pl-1 break-word"
            v-katex:auto
            v-html="choice"
            >{{ choice }}</label
          >
        </div>
      </div>
      <div
        id="problem-footer"
        class="flex flex-col items-center sm:grid grid-cols-3"
      >
        <div
          id="problem-footer--user-choice"
          class="mb-3 sm:mb-0"
          v-katex:auto
          :key="pickedAnswer"
        >
          Deine Antwort: <span v-html="pickedAnswer">{{ pickedAnswer }}</span>
        </div>
        <div
          v-show="feedback.show"
          v-html="feedback.text"
          id="problem-footer--feedback"
          class="text-center mb-3 sm:mb-0"
        ></div>
        <div
          id="problem-footer--check-button"
          class="text-center sm:col-start-3"
        >
          <FormulateInput
            type="button"
            value="Prüfen"
            @click.prevent="
              checkAnswer(isSolvedBy, attemptBy, points, problemId)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LikeDislike from "@/components/LikeDislike.vue";
import { db } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "Problem",
  components: {
    LikeDislike
  },
  props: {
    attemptBy: Number,
    choices: Array,
    dislikes: Number,
    isLikedBy: Boolean,
    isDislikedBy: Boolean,
    isSolvedBy: Boolean,
    likes: Number,
    points: Number,
    problemId: String,
    problemNo: Number,
    textFirst: String,
    textSecond: String,
    textThird: String,
    svg: String,
    anotherSvg: String
  },
  data() {
    return {
      pickedAnswer: "",
      feedback: {
        show: false,
        text: ""
      }
    };
  },
  computed: {
    ...mapState(["activeUser"])
  },
  methods: {
    ...mapActions(["bindActiveUser", "incrementSolvedOnAttempt"]),
    deleteCurrentFeedback() {
      this.feedback.text = "";
    },
    async checkAnswer(isSolvedBy, attemptBy, points, problemId) {
      if (this.pickedAnswer) {
        this.feedback.show = true;
        const problemDocument = await db
          .collection("problems")
          .doc(problemId)
          .get();
        const solution = problemDocument.data().solution;
        if (this.pickedAnswer === solution) {
          this.feedback.text = (
            "Richtig! " + `<span class="lnr lnr-smile"></span>`
          ).fontcolor("#61B231");
          if (!isSolvedBy) {
            db.collection("problems")
              .doc(problemId)
              .update({ [`isSolvedBy.${this.activeUser.nickname}`]: true });
            this.addPoints(attemptBy, this.activeUser, points);
            this.incrementSolvedOnAttempt({
              attemptBy: attemptBy,
              problemId: problemId
            });
          }
        } else {
          this.feedback.text = (
            "Leider falsch... " + `<span class="lnr lnr-sad"></span>`
          ).fontcolor("#FF5555");
          if (attemptBy < 5 && !isSolvedBy) {
            db.collection("problems")
              .doc(problemId)
              .update({
                [`attemptBy.${this.activeUser.nickname}`]: ++attemptBy
              });
          }
        }
      } else {
        this.feedback.show = true;
        this.feedback.text = (
          "Du hast noch auf keine Antwort geklickt. " +
          `<span class="lnr lnr-neutral"></span>`
        ).italics();
      }
    },
    async addPoints(attemptBy, activeUser, points) {
      const ref = db.collection("users").doc(activeUser.id);
      try {
        switch (attemptBy) {
          case 1:
            await ref.update({ points: (activeUser.points += points * 4) });
            break;
          case 2:
            await ref.update({ points: (activeUser.points += points * 3) });
            break;
          case 3:
            await ref.update({ points: (activeUser.points += points * 2) });
            break;
          case 4:
            await ref.update({ points: (activeUser.points += points * 1) });
            break;
        }
      } catch {
        console.log("error");
      }
    }
  },
  mounted() {
    this.bindActiveUser();
  }
};
</script>

<style></style>
