<template>
  <div id="title" class="font-black text-4xl mb-5">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>
