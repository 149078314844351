<template>
  <div
    id="footer"
    class="flex flex-col items-center text-xs text-gray-600 pb-5 bg-yellow-50"
  >
    <div class="pb-2 pt-2">
      <a class="overflow-hidden" href="/">
        <img src="../assets/logo.svg" alt="Logo" class="h-10"
      /></a>
    </div>
    <div class="flex flex-wrap justify-center space-x-1.5">
      <router-link class="hover:underline" to="/about">Über Pangea</router-link>
      <span>&#183;</span>
      <router-link class="hover:underline" to="/contact">Kontakt</router-link>
      <span>&#183;</span>
      <a
        class="hover:underline"
        href="https://pangea-wettbewerb.de/impressum/"
        target="_blank"
        >Impressum</a
      >
      <span>&#183;</span>
      <a
        class="hover:underline"
        href="https://pangea-wettbewerb.de/datenschutz/"
        target="_blank"
        >Datenschutzerklärung</a
      >
      <span>&#183;</span>
      <a
        class="hover:underline"
        href="https://pangea-wettbewerb.de/agb/"
        target="_blank"
        >Allgemeine Geschäftsbedingungen</a
      >
      <span>&#183;</span>
      <a
        class="hover:underline"
        href="https://pangea-wettbewerb.de"
        target="_blank"
        >www.pangea-wettbewerb.de</a
      >
    </div>
    <div class="flex flex-col items-center pt-3 space-y-1">
      <div class="flex flex-wrap justify-center space-x-1">
        <span>&copy; 2021 Pangea-Mathematikwettbewerb.</span>
        <span>Alle Rechte vorbehalten.</span>
      </div>
      <div class="text-center">
        Pangea<span style="font-style: italic;">pp</span> wurde mit vue.js and
        tailwindcss von Serdar Altuntas erstellt.
      </div>
      <div class="space-x-5 pt-2">
        <a
          href="http://twitter.com/pangea_mathe?lang=de"
          target="_blank"
          style="color: black;"
          ><fa-icon
            class="hover:text-pangea-green"
            :icon="['fab', 'twitter']"
            size="2x"
        /></a>
        <a
          href="https://www.instagram.com/pangea_mathe/"
          target="_blank"
          style="color: black;"
          ><fa-icon
            class="hover:text-pangea-green"
            :icon="['fab', 'instagram']"
            size="2x"
        /></a>
        <a
          href="https://de-de.facebook.com/pangea.wettbewerb.de/"
          target="_blank"
          style="color: black;"
          ><fa-icon
            class="hover:text-pangea-green"
            :icon="['fab', 'facebook']"
            size="2x"
        /></a>
        <a
          href="https://www.youtube.com/channel/UCTQXTR6dORc8OzZemqipEhA"
          target="_blank"
          style="color: black;"
          ><fa-icon
            class="hover:text-pangea-green"
            :icon="['fab', 'youtube']"
            size="2x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
