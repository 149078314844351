<template>
  <div>
    <div class="bg-yellow-50 rounded-2xl shadow-md m-4">
      <div
        :class="
          `bg-${bgImage} bg-cover w-40 h-40 flex flex-col items-center justify-evenly`
        "
      >
        <div class="font-semibold">
          {{ title }}
        </div>
        <div class="font-black text-3xl">
          {{ stat }}
        </div>
        <div v-katex:auto v-html="formula"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsTopic",
  props: {
    title: String,
    stat: Number,
    formula: String,
    bgImage: String
  }
};
</script>
