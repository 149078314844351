<template>
  <div>
    <router-link :to="link">
      <div class="flex justify-center items-baseline space-x-1 font-medium">
        <span :class="`lnr lnr-${iconName} text-3xl sm:text-lg`"></span>
        <span class="text-lg hidden sm:inline hover:underline">{{
          itemTitle
        }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    link: String,
    itemTitle: String,
    iconName: String
  }
};
</script>

<style></style>
