<template>
  <div id="user-profile" class="m-0 m-auto sm:max-w-sm" v-if="currentUser">
    <div v-if="!activeUser" class="flex flex-col items-center mt-10">
      <button type="button" class="flex italic" disabled>
        <svg
          class="animate-spin h-5 w-5 mr-3 border bg-gray-200 rounded"
          viewBox="0 0 24 24"
        >
          <!-- ... -->
        </svg>
        Profildaten werden geladen
      </button>
    </div>
    <div v-else>
      <TheTitle :title="'Profil'" />
      <FormulateForm
        #default="{hasErrors}"
        class="bg-gray-50 rounded px-5 pt-5 pb-1"
      >
        <FormulateInput
          type="text"
          label="Nickname"
          v-model="nickname"
          validation="required|min:3|max:23"
          :errors="[
            isTaken ? `Nickname '${nickname}' ist schon vergeben.` : null,
            hasSpaces ? 'Nickname darf keine Leerzeichen enthalten.' : null,
            startsEndsWithDot
              ? 'Nickname darf nicht mit einem Punkt anfangen oder enden.'
              : null
          ]"
          :disabled="false"
        />

        <!-- <FormulateInput
        type="text"
        label="E-Mail"
        v-model="email"
        validation="required|email"
      /> -->
        <FormulateInput
          v-if="userType === 'Schüler*in'"
          v-model="gender"
          name="Geschlecht"
          type="select"
          :options="{
            female: 'weiblich',
            male: 'männlich',
            diverse: 'divers'
          }"
          label="Geschlecht"
          validation="required"
        />
        <FormulateInput
          v-if="userType === 'Schüler*in'"
          v-model="federalState"
          name="Bundesland"
          type="select"
          :options="{
            BadenWuerttemberg: 'Baden-Württemberg',
            Bayern: 'Bayern',
            Berlin: 'Berlin',
            Bremen: 'Bremen',
            Brandenburg: 'Brandenburg',
            Hamburg: 'Hamburg',
            Hessen: 'Hessen',
            MecklenburgVorpommern: 'Mecklenburg-Vorpommern',
            Niedersachsen: 'Niedersachsen',
            NordrheinWestfalen: 'Nordrhein-Westfalen',
            RheinlandPfalz: 'Rheinland-Pfalz',
            Saarland: 'Saarland',
            Sachsen: 'Sachsen',
            SachsenAnhalt: 'Sachsen-Anhalt',
            SchleswigHolstein: 'Schleswig-Holstein',
            Thueringen: 'Thüringen'
          }"
          label="Bundesland"
          validation="required"
        />
        <FormulateInput
          v-if="userType === 'Schüler*in'"
          v-model="school"
          name="Schulform"
          type="select"
          :options="{
            Gesamtschule: 'Gesamtschule',
            Grundschule: 'Grundschule',
            Gymnasium: 'Gymnasium',
            Hauptschule: 'Hauptschule',
            Realschule: 'Realschule',
            Sonstige: 'Sonstige'
          }"
          validation="required"
          label="Schulform"
        />

        <FormulateInput
          v-if="userType === 'Schüler*in'"
          v-model="grade"
          name="Jahrgang"
          type="select"
          :options="{
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
            '7': '7',
            '8': '8',
            '9': '9',
            '10': '10'
          }"
          validation="required"
          label="Jahrgang"
        />
        <!-- <FormulateInput
        label="Avatar"
        type="image"
        name="headshot"
        help="Select a png, jpg or svg to upload."
        validation="mime:image/jpeg,image/png,image/svg"
        v-model="image"
      /> -->
        <!-- <FormulateInput
          type="select"
          label="Avatar"
          :placeholder="mathematicians[avatar]"
          @click="showOrCloseAvatarChoice"
          help="Optional"
        /> -->
        <div class="flex space-x-5 align-center">
          <FormulateInput
            input-class="border rounded px-2 focus:outline-none shadow py-2"
            help-class="text-left text-xs text-gray-600 pt-1"
            type="button"
            value="Avatar auswählen"
            @click="showOrCloseAvatarChoice"
            help="Optional"
          />
          <span class="py-2">{{ mathematicians[avatar] }}</span>
        </div>
        <FormulateInput
          type="button"
          value="Aktualisieren"
          :disabled="hasErrors || hasSpaces || isTaken || startsEndsWithDot"
          @click="updateData"
        />
      </FormulateForm>
      <div
        class="flex flex-col items-center space-y-4 justify-center mt-10 text-sm"
      >
        <div>
          <router-link to="changeemail" class="hover:underline"
            >E-Mail ändern <span class="lnr lnr-pencil"></span>
          </router-link>
        </div>
        <div>
          <router-link to="changepassword" class="hover:underline"
            >Passwort ändern <span class="lnr lnr-pencil"></span>
          </router-link>
        </div>
        <div class="pt-10">
          <router-link to="deleteaccount" class="hover:underline"
            >Account löschen <span class="lnr lnr-trash"></span>
          </router-link>
        </div>
      </div>
      <div
        v-show="activeAvatarChoice"
        id="choose-avatar"
        class="fixed overflow-scroll z-40 top-0 left-0 right-0 bottom-0 w-full min-h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center"
      >
        <div
          class="relative w-2/3 rounded flex flex-col text-center text-lg sm:w-2/3"
        >
          <input
            type="button"
            class="absolute top-0 right-0 cursor-pointer w-8 h-8 bg-white rounded-full flex flex-col items-center justify-center m-1 outline-none shadow"
            value="x"
            @click="showOrCloseAvatarChoice"
          />
          <span class="text-xl font-black my-10 bg-white rounded p-2"
            >Wähle bitte ein Avatar aus.</span
          >
          <div class="grid grid-cols-1 mb-10 sm:grid-cols-4">
            <div
              id="avatar-item"
              class="m-0 m-auto flex flex-col items-center text-center"
              v-for="(mathematician, portrait) in mathematicians"
              :key="portrait"
            >
              <input
                type="button"
                @click.prevent="defineAvatarName(portrait, mathematician)"
                :class="
                  `w-20 h-20 border-0 rounded-full m-4 bg-cover bg-white bg-${portrait} flex flex-col items-center align-center justify-center text-4xl cursor-pointer shadow`
                "
              />
              <div id="avatar-name" class="bg-white px-1 rounded mb-7">
                {{ mathematician }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        v-show="activeAvatarChoice"
        id="choose-avatar"
        class="absolute z-40 top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-20 flex flex-col justify-center items-center"
      >
        <div
          class="relative bg-white w-1/2 overflow-y rounded flex flex-col text-center text-lg"
        >
          <input
            type="button"
            class="absolute top-0 right-0 cursor-pointer w-8 h-8 bg-white rounded-full flex flex-col items-center justify-center m-1 outline-none shadow"
            value="x"
            @click="showOrCloseAvatarChoice"
          />
          <span class="text-xl font-black my-5"
            >Wähle bitte ein Avatar aus.</span
          >
          <div class="flex flex-wrap">
            <div
              id="avatar-item"
              class="m-0 m-auto flex flex-col items-center text-center"
              v-for="(mathematician, portrait) in mathematicians"
              :key="portrait"
            >
              <input
                type="button"
                @click.prevent="defineAvatarName(portrait, mathematician)"
                :class="
                  `w-20 h-20 border-0 rounded-full m-4 bg-cover bg-white bg-${portrait} flex flex-col items-center align-center justify-center text-4xl cursor-pointer shadow`
                "
              />
              <div id="avatar-name">
                {{ mathematician }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import TheTitle from "@/components/TheTitle.vue";
import { mapState, mapActions } from "vuex";
import { auth, db } from "@/firebase.js";
import firebase from "firebase/app";

export default {
  components: {
    TheTitle
  },
  data() {
    return {
      avatar: "",
      avatarName: "",
      currentAvatar: "",
      nameOfTheMathematician: "",
      currentNickname: "",
      nickname: "",
      federalState: "",
      currentFederalState: "",
      school: "",
      currentSchool: "",
      grade: "",
      currentGrade: "",
      currentPassword: "",
      notEditable: true,
      email: "",
      // image: "",
      newPassword: "",
      userType: "",
      currentGender: "",
      gender: "",
      activeAvatarChoice: false,
      mathematicians: {
        gauss: "Carl Friedrich Gauß",
        euler: "Leonhard Euler",
        germain: "Sophie Germain",
        lovelace: "Ada Lovelace",
        kowalewskaja: "Sofja Kowalewskaja",
        noether: "Emmy Noether",
        riemann: "Bernhard Riemann",
        hilbert: "David Hilbert"
      }
    };
  },
  computed: {
    ...mapState(["currentUser", "activeUser", "nicknames"]),
    // ...mapGetters(["getCurrentUserDisplayName", "getCurrentUserEmail", "getActiveUserSchool", "getActiveUserGender", "getActiveUserGrade", "getActiveUserUserType", "getActiveUserFederalState"]),
    hasSpaces() {
      return /\s/.test(this.nickname);
    },
    startsEndsWithDot() {
      if (
        this.nickname[0] == "." ||
        this.nickname[this.nickname.length - 1] == "."
      ) {
        return true;
      } else {
        return false;
      }
    },
    isTaken() {
      if (this.nicknames) {
        let nicknameList = [];
        this.nicknames.forEach(user => {
          nicknameList.push(user.id);
        });
        let index = nicknameList.indexOf(this.currentUser.displayName);
        if (index > -1) {
          nicknameList.splice(index, 1);
          if (nicknameList.includes(this.nickname)) {
            return true;
          }
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions(["bindNicknames", "unbindNicknames"]),
    showOrCloseAvatarChoice() {
      this.activeAvatarChoice = !this.activeAvatarChoice;
    },
    defineAvatarName(portrait, mathematician) {
      this.nameOfTheMathematician = mathematician;
      this.avatar = portrait;
      this.activeAvatarChoice = false;
    },
    updateData() {
      this.unbindNicknames();
      auth.currentUser.updateProfile({ displayName: this.nickname });
      if (this.userType === "Schüler*in") {
        db.collection("users")
          .doc(this.currentUser.uid)
          .update({
            nickname: this.nickname,
            school: this.school,
            grade: this.grade,
            federalState: this.federalState,
            avatar: this.avatar
          });
      } else {
        db.collection("users")
          .doc(this.currentUser.uid)
          .update({
            nickname: this.nickname,
            avatar: this.avatar
          })
          .then(() => {
            console.log(this.nickname);
          });
      }
      // if (this.image) {
      //   storage
      //     .ref("users/" + auth.currentUser.uid + "/avatar.jpg")
      //     .put(this.image.fileList[0])
      //     .then(() => {
      //       storage
      //         .ref("users/" + auth.currentUser.uid + "/avatar.jpg")
      //         .getDownloadURL()
      //         .then(url => {
      //           auth.currentUser.updateProfile({ photoURL: url });
      //         });
      //       setTimeout(() => {
      //         this.$router.go();
      //       }, 800);
      //     })
      //     .catch(error => {
      //       console.log(error.message);
      //     });
      // }
      db.collection("nicknames")
        .doc(this.currentNickname)
        .delete();
      db.collection("nicknames")
        .doc(this.nickname)
        .set({});
      this.updateStatistics({
        federalState: this.federalState,
        currentFederalState: this.currentFederalState
      });
      this.updateStatistics({
        grade: this.grade,
        currentGrade: this.currentGrade
      });
      this.updateStatistics({
        school: this.school,
        currentSchool: this.currentSchool
      });
      this.updateStatistics({
        gender: this.gender,
        currentGender: this.currentGender
      });
    },
    updateStatistics(payload) {
      let increment = firebase.firestore.FieldValue.increment(1);
      let decrement = firebase.firestore.FieldValue.increment(-1);
      if (payload.federalState) {
        db.collection("statistics")
          .doc("federalStates")
          .update({ [`${payload.federalState}`]: increment });
        db.collection("statistics")
          .doc("federalStates")
          .update({ [`${payload.currentFederalState}`]: decrement });
      }
      if (payload.grade) {
        db.collection("statistics")
          .doc("grades")
          .update({ [`${payload.grade}`]: increment });
        db.collection("statistics")
          .doc("grades")
          .update({ [`${payload.currentGrade}`]: decrement });
      }
      if (payload.school) {
        db.collection("statistics")
          .doc("school")
          .update({ [`${payload.school}`]: increment });
        db.collection("statistics")
          .doc("school")
          .update({ [`${payload.currentSchool}`]: decrement });
      }
      if (payload.gender) {
        db.collection("statistics")
          .doc("gender")
          .update({ [`${payload.gender}`]: increment });
        db.collection("statistics")
          .doc("gender")
          .update({ [`${payload.currentGender}`]: decrement });
      }
    }
  },
  mounted() {
    this.bindNicknames();
    setTimeout(() => {
      if (this.currentUser) {
        this.nickname = this.currentUser.displayName;
        this.currentNickname = this.currentUser.displayName;
        this.email = this.currentUser.email;
        this.school = this.activeUser.school;
        this.currentSchool = this.activeUser.school;
        this.grade = this.activeUser.grade;
        this.currentGrade = this.activeUser.grade;
        this.federalState = this.activeUser.federalState;
        this.currentFederalState = this.activeUser.federalState;
        this.userType = this.activeUser.userType;
        this.gender = this.activeUser.gender;
        this.currentGender = this.activeUser.gender;
        this.avatar = this.activeUser.avatar;
        this.currentAvatar = this.activeUser.avatar;
      }
    }, 350);
  }
};
</script>

<style></style>
