<template>
  <div
    id="like-dislike"
    :problemId="problemId"
    :likes="likes"
    :dislikes="dislikes"
    :isLikedBy="isLikedBy"
    :isDislikedBy="isDislikedBy"
  >
    <span
      class="lnr lnr-thumbs-up cursor-pointer"
      v-if="!isLikedBy"
      @click="like"
    ></span>
    <span
      class="lnr lnr-thumbs-up cursor-pointer"
      style="color: #61B231;"
      v-else
      @click="like"
    ></span>
    <span style="font-weight: normal;"> {{ likes }} </span>
    <span
      class="lnr lnr-thumbs-down cursor-pointer"
      v-if="!isDislikedBy"
      @click="dislike"
    ></span>
    <span
      class="lnr lnr-thumbs-down cursor-pointer"
      style="color: #61B231;"
      v-else
      @click="dislike"
    ></span>
    <span style="font-weight: normal;"> {{ dislikes }} </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    problemId: String,
    likes: Number,
    dislikes: Number,
    isLikedBy: Boolean,
    isDislikedBy: Boolean
  },
  methods: {
    ...mapActions(["likeAction", "dislikeAction"]),
    like() {
      this.likeAction({
        problemId: this.problemId,
        isLikedBy: this.isLikedBy,
        isDislikedBy: this.isDislikedBy,
        likes: this.likes,
        dislikes: this.dislikes
      });
    },
    dislike() {
      this.dislikeAction({
        problemId: this.problemId,
        isLikedBy: this.isLikedBy,
        isDislikedBy: this.isDislikedBy,
        likes: this.likes,
        dislikes: this.dislikes
      });
    }
  }
};
</script>
