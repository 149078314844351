<template>
  <div
    v-show="isShown"
    id="notification"
    class="h-full w-full fixed m-0 m-auto top-0 right-0 bottom-0 left-0 z-50 bg-black bg-opacity-30 flex flex-col items-center justify-center rounded"
  >
    <div
      :type="type"
      id="notification-inner"
      :class="
        `relative w-5/6 h-auto rounded-xl bg-${color} opacity-100 z-50 flex flex-col items-center justify-center p-10 text-xl text-yellow-50 font-semibold sm:w-3/5`
      "
    >
      {{ note }}
      <div
        v-if="show"
        class="absolute top-0 right-0 mt-2 mr-2 text-yellow-50 cursor-pointer"
        @click.prevent="closeNotification"
      >
        <span class="lnr lnr-cross"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheNotification",
  data() {
    return {
      type: "",
      isShown: false,
      note: "Ein unbekannter Fehler ist aufgetreten.",
      show: true
    };
  },
  computed: {
    color() {
      switch (this.type) {
        case "success":
          return "pangea-green";
        case "error":
          return "red-500";
        default:
          return "blue-500";
      }
    }
  },
  methods: {
    showNotification() {
      this.isShown = true;
    },
    closeNotification() {
      this.isShown = false;
    }
  }
};
</script>

<style>
#notification .lnr:hover {
  font-weight: 900;
}
</style>
