<template>
  <div id="problems">
    <TheFilter />
    <div v-if="problems && showProblems">
      <div class="flex items-center space-x-1">
        <div
          @click="changeChevron"
          class="flex items-center justify-center border-black rounded-full w-7 h-7 bg-black text-yellow-50 cursor-pointer"
        >
          <span
            v-if="toggleChevron"
            class="lnr lnr-chevron-right font-black"
          ></span>
          <span
            v-if="!toggleChevron"
            class="lnr lnr-chevron-down font-black"
          ></span>
        </div>
        <div>
          Hinweis zum Punkteschema <span v-if="toggleChevron">aufklappen</span>
          <span v-else>zuklappen</span>
        </div>
      </div>
      <div v-show="!toggleChevron" class="ml-8">
        Beantwortest Du eine Aufgabe beim ...
        <ul>
          <li>1. Versuch richtig, wird die Punktzahl mit 4 multipliziert.</li>
          <li>2. Versuch richtig, wird die Punktzahl mit 3 multipliziert.</li>
          <li>3. Versuch richtig, wird die Punktzahl mit 2 multipliziert.</li>
          <li>4. Versuch richtig, erhälst du nur die Aufgabenpunktzahl.</li>
          <li>
            5. Versuch, erhälst du keine Punktzahl, da es zu jeder Aufgabe 5
            Antwortmöglichkeiten gibt.
          </li>
        </ul>
        Diese Regelung gilt nur für Pangeapp und
        <span style="font-style: italic">nicht</span> für den offiziellen
        Pangea-Mathematikwettbewerb!
      </div>
    </div>
    <div
      v-if="problems && problems.length == 0 && showProblems"
      class="text-red-600 text-center text-xl mt-10"
    >
      Für deine Auswahl existieren derzeit keine Aufgaben.
    </div>
    <div v-else-if="problems && problems.length != 0 && showProblems">
      <div class="font-black text-4xl mt-20 mb-5 text-center">
        Aufgaben
      </div>
      <div v-for="(problem, index) in problems" :key="index" class="mb-10">
        <Problem
          :key="index"
          :attemptBy="problem.attemptBy[currentUser.displayName]"
          :choices="problem.choices"
          :problemId="problem.id"
          :likes="problem.likes"
          :dislikes="problem.dislikes"
          :isLikedBy="problem.isLikedBy[currentUser.displayName]"
          :isDislikedBy="problem.isDislikedBy[currentUser.displayName]"
          :isSolvedBy="problem.isSolvedBy[currentUser.displayName]"
          :points="problem.points"
          :problemNo="index + 1"
          :textFirst="problem.text"
          :textSecond="problem.secondText"
          :textThird="problem.thirdText"
          :svg="problem.svg"
          :anotherSvg="problem.anotherSvg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Problem from "@/components/Problem.vue";
import TheFilter from "@/components/TheFilter.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Problems",
  components: {
    Problem,
    TheFilter
  },
  data() {
    return {
      showProblems: false,
      toggleChevron: false
    };
  },
  computed: {
    ...mapState(["problems", "currentUser"])
  },
  methods: {
    ...mapActions(["unbindProblems"]),
    changeChevron() {
      this.toggleChevron = !this.toggleChevron;
    }
  },
  destroyed() {
    this.unbindProblems();
  }
};
</script>

<style></style>
