<template>
  <div id="statistics">
    <TheTitle title="Statistiken" class="text-center" />
    <TableOfContents
      :titles="{
        numberOfProblems: 'Anzahl der Aufgaben',
        mostLikedProblems: 'Top 3 Aufgaben',
        genderDistribution: 'Nutzerstatistiken',
        ranking: 'Ranking'
      }"
    />

    <div
      id="numberOfProblems"
      ref="numberOfProblems"
      class="text-2xl text-center font-black pt-12 mb-4 sm:pt-32 sm:pl-4"
    >
      Anzahl der Aufgaben
    </div>
    <div
      v-if="statistics"
      class="flex flex-col items-center sm:flex-row flex-wrap"
    >
      <StatisticsTopics title="Total" :stat="statisticsAsObject.topics.total" />
      <StatisticsTopics
        title="Algebra"
        :stat="statisticsAsObject.topics.Algebra"
        bgImage="algebra"
      />
      <StatisticsTopics
        title="Arithmetik"
        :stat="statisticsAsObject.topics.Arithmetik"
        bgImage="arithmetics"
      />
      <StatisticsTopics
        title="Funktionen"
        :stat="statisticsAsObject.topics.Funktionen"
        bgImage="functions"
      />
      <StatisticsTopics
        title="Geometrie"
        :stat="statisticsAsObject.topics.Geometrie"
        bgImage="geometry"
      />
      <StatisticsTopics
        title="Kombinatorik"
        :stat="statisticsAsObject.topics.Kombinatorik"
        bgImage="combinatorics"
      />
      <StatisticsTopics
        title="Logik"
        :stat="statisticsAsObject.topics.Logik"
        bgImage="logics"
      />
      <StatisticsTopics
        title="Wahrscheinlichkeit"
        :stat="statisticsAsObject.topics.Wahrscheinlichkeit"
        bgImage="probability"
      />
      <StatisticsTopics
        title="Zahlen"
        :stat="statisticsAsObject.topics.Zahlen"
        bgImage="numbers"
      />
    </div>

    <div id="top-3-most-liked-problems">
      <div
        id="mostLikedProblems"
        ref="mostLikedProblems"
        class="text-2xl text-center font-black pt-12 mb-4 sm:pt-32 sm:pl-4"
      >
        Top 3 Aufgaben
      </div>
      <div v-for="(problem, index) in problems" :key="index">
        <Problem
          :key="index"
          :attemptBy="problem.attemptBy[currentUser.displayName]"
          :choices="problem.choices"
          :problemId="problem.id"
          :likes="problem.likes"
          :dislikes="problem.dislikes"
          :isLikedBy="problem.isLikedBy[currentUser.displayName]"
          :isDislikedBy="problem.isDislikedBy[currentUser.displayName]"
          :isSolvedBy="problem.isSolvedBy[currentUser.displayName]"
          :points="problem.points"
          :problemNo="index + 1"
          :textFirst="problem.text"
          :svg="problem.svg"
        />
      </div>
    </div>
    <div
      id="genderDistribution"
      ref="genderDistribution"
      class="text-2xl text-center font-black pt-12 mb-4 sm:pt-32 sm:pl-4"
    >
      Statistiken über angemeldete Schüler*innen
    </div>
    <div>
      <div class="font-bold text-xs text-center">Anzahl der Schüler*innen</div>
      <div
        v-if="statisticsAsObject"
        class="font-black text-2xl text-center m-0 m-auto mb-10 mt-1 rounded-full bg-pangea-green w-24 h-24 flex flex-col justify-center text-white shadow-md"
      >
        {{ statisticsAsObject.school.total }}
      </div>
    </div>
    <GenderData />
    <GradeData />
    <SchoolData />
    <FederalStateData />
    <div
      id="ranking"
      ref="ranking"
      class="text-2xl text-center font-black pt-12 mb-4 sm:pt-32 sm:pl-4"
    >
      Ranking
    </div>
    <div v-if="students" class="flex flex-wrap justify-center">
      <div
        v-for="grade in [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11+'
        ]"
        :key="grade"
        class="mx-8 mb-12"
      >
        <div class="text-xl bg-black text-yellow-50 font-bold mb-3 text-center">
          Jahrgang {{ grade }}
        </div>
        <table class="table-auto text-center">
          <tr class="font-semibold">
            <td>Platz</td>
            <td>Nickname</td>
            <td>Punkte</td>
          </tr>
          <tr v-for="(student, item) in getStudentsByGrade(grade)" :key="item">
            <td>{{ item + 1 }}</td>
            <td>{{ student.nickname }}</td>
            <td>{{ student.points }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TheTitle from "@/components/TheTitle.vue";
import StatisticsTopics from "@/components/StatisticsTopics.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import Problem from "@/components/Problem.vue";
import GenderData from "@/components/GenderData.vue";
import GradeData from "@/components/GradeData.vue";
import FederalStateData from "@/components/FederalStateData.vue";
import SchoolData from "@/components/SchoolData.vue";

export default {
  components: {
    TheTitle,
    StatisticsTopics,
    TableOfContents,
    Problem,
    GenderData,
    GradeData,
    FederalStateData,
    SchoolData
  },
  methods: {
    ...mapActions([
      "bindStatistics",
      "bindProblems",
      "unbindProblems",
      "bindStudents"
    ])
  },
  mounted() {
    this.bindStatistics();
    this.bindProblems("likes");
    this.bindStudents();
  },
  computed: {
    ...mapState(["statistics", "problems", "currentUser", "students"]),
    ...mapGetters(["getStudentsByGrade"]),
    statisticsAsObject() {
      const arrayToObject = array =>
        array.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      if (this.statistics) {
        return arrayToObject(this.statistics);
      }
      return null;
    }
  },
  destroyed() {
    this.unbindProblems();
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #fffbeb;
}

td {
  border-bottom: 1px solid black;
  padding: 3px;
}
</style>
