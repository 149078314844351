<template>
  <header id="header" class="lg:w-full mx-auto">
    <div class="flex items-center justify-between px-4">
      <span class="sr-only">Pangea-Mathematikwettbewerb</span>
      <div class="py-1">
        <a class="overflow-hidden" href="/">
          <img src="../assets/logo.svg" alt="Logo" class="h-16"
        /></a>
      </div>
      <div
        v-if="showAvatar"
        @mouseover.prevent="showDropDownMenu"
        @mouseleave.prevent="closeDropDownMenu"
        class="flex flex-col font-semibold text-2xl items-center relative sm:flex-row"
      >
        <ShortUserInfo class="hidden sm:contents" />
        <TheAvatar />
        <TheDropDownMenu v-show="isDroppedDown" />
      </div>
    </div>
  </header>
</template>

<script>
import TheDropDownMenu from "../components/TheDropDownMenu.vue";
import TheAvatar from "../components/TheAvatar.vue";
import ShortUserInfo from "../components/ShortUserInfo.vue";
import { mapState } from "vuex";

export default {
  name: "Header",
  components: {
    TheDropDownMenu,
    TheAvatar,
    ShortUserInfo
  },
  data() {
    return {
      isDroppedDown: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    showAvatar() {
      if (this.currentUser) {
        if (this.currentUser.emailVerified) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    showDropDownMenu() {
      this.isDroppedDown = true;
    },
    closeDropDownMenu() {
      this.isDroppedDown = false;
    },
    // showDropDownMenuOnClick() {
    //   this.isDroppedDown = !this.isDroppedDown;
    // },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isDroppedDown = false;
      }
    }
  },
  mounted() {
    // document.addEventListener("mouseover", this.close);
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("mouseover", this.close);
    document.removeEventListener("click", this.close);
  }
};
</script>

<style></style>
