var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 m-auto h-full w-full sm:w-1/2",attrs:{"id":"the-filter"}},[_c('FormulateForm',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"type":"select","name":"Jahrgang","options":{
        '3': 'Jahrgang 3',
        '4': 'Jahrgang 4',
        '5': 'Jahrgang 5',
        '6': 'Jahrgang 6',
        '7': 'Jahrgang 7',
        '8': 'Jahrgang 8',
        '9': 'Jahrgang 9',
        '10': 'Jahrgang 10'
      },"placeholder":"Jahrgang auswählen...","validation":"required","validation-behavior":"live"},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('FormulateInput',{attrs:{"type":"select","name":"Schwierigkeit","options":{
        1: '1-Punkt-Aufgaben',
        2: '2-Punkte-Aufgaben',
        3: '3-Punkte-Aufgaben',
        4: '4-Punkte-Aufgaben',
        5: '5-Punkte-Aufgaben'
      },"placeholder":"Schwierigkeit auswählen...","validation":"required","validation-behavior":"live"},model:{value:(_vm.points),callback:function ($$v) {_vm.points=_vm._n($$v)},expression:"points"}}),_c('FormulateInput',{attrs:{"type":"select","options":{
        Algebra: 'Algebra',
        Arithmetik: 'Arithmetik',
        Funktionen: 'Funktionen',
        Geometrie: 'Geometrie',
        Kombinatorik: 'Kombinatorik',
        Logik: 'Logik',
        Wahrscheinlichkeit: 'Wahrscheinlichkeit',
        Zahlen: 'Zahlen',
        '': 'keine Auswahl'
      },"placeholder":"Themengebiet auswählen...","help":"Optional"},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}}),_c('FormulateInput',{attrs:{"type":"submit","value":"Filtern","disabled":hasErrors},on:{"click":function($event){return _vm.filter(_vm.grade, _vm.points, _vm.topic, _vm.year)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }