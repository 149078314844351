<template>
  <div class="sm:w-1/3 md:w-1/4 lg:w-1/5">
    <div v-if="mode === 'resetPassword'">
      <FormulateForm #default="{hasErrors}">
        <FormulateInput
          v-model="newPassword"
          name="Passwort"
          type="password"
          placeholder="Neues Passwort"
          validation="required"
        />
        <FormulateInput
          v-model="confirmNewPassword"
          name="Passwort wiederholen"
          type="password"
          placeholder="Neues Passwort wiederholen"
          validation="required|confirm:Passwort"
          :validation-messages="{
            confirm: 'Passwörter stimmen nicht überein.'
          }"
          @keypress.enter="handleResetPassword"
        />
        <FormulateInput
          type="button"
          @click.prevent="handleResetPassword"
          :disabled="hasErrors"
        >
          speichern
        </FormulateInput>
      </FormulateForm>
    </div>
    <div v-else-if="mode === 'verifyEmail'">
      Du hast deine E-Mail-Adresse erfolgreich verifiziert. Nun wirst du zur
      Anmeldeseite weitergeleitet.
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase.js";
import { mapActions, mapState } from "vuex";

export default {
  /* eslint-disable no-unused-vars */
  data() {
    return {
      mode: "",
      newPassword: null,
      confirmNewPassword: null,
      actionCode: null,
      continueUrl: null,
      lang: null,
      successMessage:
        "Du hast erfolgreich ein neues Passwort erstellt. Nun wirst du zur Anmeldeseite weitergeleitet."
    };
  },
  computed: {
    ...mapState(["error"])
  },
  methods: {
    getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }
      /* eslint-disable no-useless-escape */
      name = name.replace(/[\[\]]/g, "\\$&");
      /* eslint-disable no-useless-escape */
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    ...mapActions(["errorMessageFromFirebase"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.go();
      }, 3000);
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/signin");
      }, 4000);
    },
    handleResetPassword() {
      auth
        .verifyPasswordResetCode(this.actionCode)
        .then(email => {
          let accountEmail = email;

          // TODO: Show the reset screen with the user's email and ask the user for
          // the new password.

          // Save the new password.
          auth
            .confirmPasswordReset(this.actionCode, this.newPassword)
            .then(resp => {
              // Password reset has been confirmed and new password updated.

              // TODO: Display a link back to the app, or sign-in the user directly
              // if the page belongs to the same domain as the app:
              // auth.signInWithEmailAndPassword(accountEmail, newPassword);

              // TODO: If a continue URL is available, display a button which on
              // click redirects the user back to the app via continueUrl with
              // additional state determined from that URL's parameters.
              this.successNotification();
            })
            .catch(error => {
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
              console.log(error);
              this.errorMessageFromFirebase(error);
              this.errorNotification();
            });
        })
        .catch(error => {
          console.log(error);
          this.errorMessageFromFirebase(error);
          this.errorNotification();
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });
    },
    handleVerifyEmail() {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // this.mode = this.getParameterByName("mode");
      // Get the one-time code from the query parameter.
      // let actionCode = this.getParameterByName("oobCode");
      // (Optional) Get the continue URL from the query parameter if available.
      // let continueUrl = this.getParameterByName("continueUrl");
      // (Optional) Get the language code if available.
      // let lang = this.getParameterByName("lang") || "de";
      // Try to apply the email verification code.
      auth
        .applyActionCode(this.actionCode)
        .then(resp => {
          // Email address has been verified.

          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.

          setTimeout(() => {
            this.$router.push("/signin");
          }, 3000);
        })
        .catch(error => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          this.errorMessageFromFirebase(error);
          this.errorNotification();
        });
    }
  },
  /* eslint-disable no-unused-vars */
  mounted() {
    this.mode = this.getParameterByName("mode");
    this.actionCode = this.getParameterByName("oobCode");
    this.continueUrl = this.getParameterByName("continueUrl");
    this.lang = this.getParameterByName("lang") || "de";
    if (this.mode === "verifyEmail") {
      this.handleVerifyEmail();
    }
  }
};
</script>
