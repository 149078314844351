import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SignUp from "../views/SignUp.vue";
import SignIn from "../views/SignIn.vue";
import Problems from "../views/Problems.vue";
import Challenge from "../views/Challenge.vue";
import Statistics from "../views/Statistics.vue";
import UserProfile from "../views/UserProfile.vue";
import DeleteAccount from "../views/DeleteAccount.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ChangeEmail from "../views/ChangeEmail.vue";
import Contact from "../views/Contact.vue";
import AddNewProblem from "../views/AddNewProblem.vue";
import EmailActionHandler from "../views/EmailActionHandler.vue";
import ResetPassword from "../views/ResetPassword.vue";
import RandomProblem from "../views/RandomProblem.vue";

import { auth } from "@/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/emailactionhandler",
    name: "EmailActionHandler",
    component: EmailActionHandler
  },
  {
    path: "/problems",
    name: "Problems",
    component: Problems,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/randomproblem",
    name: "RandomProblem",
    component: RandomProblem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/challenge",
    name: "Challenge",
    component: Challenge,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/userprofile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/changeemail",
    name: "ChangeEmail",
    component: ChangeEmail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/deleteaccount",
    name: "DeleteAccount",
    component: DeleteAccount,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/addnewproblem",
    name: "AddNewProblem",
    component: AddNewProblem,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  }
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const admin = to.matched.some(record => record.meta.admin);

  if (requiresAuth && !currentUser) {
    next("/signin");
  } else if (
    (!!currentUser &&
      (to.name === "SignIn" ||
        to.name === "SignUp" ||
        to.name === "ResetPassword")) ||
    (admin &&
      currentUser.displayName !== "Goldstone" &&
      to.name === "AddNewProblem")
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
