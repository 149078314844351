<template>
  <div id="change-email" class="m-0 m-auto sm:max-w-sm">
    <TheTitle :title="'E-Mail ändern'" />
    <div>
      <span class="italic">Hinweis:</span> Nachdem Du deine E-Mail-Adresse
      geändert hast, wirst Du anschließend abgemeldet. Dir wird ein Link zur
      Verifizierung deiner neuen E-Mail-Adresse gesendet. Sobald Du deine
      E-Mail-Adresse verifiziert hast, kannst Du dich wieder anmelden.
    </div>
    <FormulateForm
      #default="{hasErrors}"
      class="bg-gray-50 rounded px-5 pt-5 pb-1"
    >
      <FormulateInput
        v-model="currentEmail"
        name="Aktuelle E-Mail"
        type="email"
        placeholder="Aktuelle E-Mail"
        validation="required|email"
      />
      <FormulateInput
        v-model="newEmail"
        name="Neue Email"
        type="email"
        placeholder="Neue E-Mail"
        validation="required|email"
      />
      <FormulateInput
        v-model="password"
        type="password"
        name="Passwort"
        placeholder="Passwort"
        validation="required"
      />

      <FormulateInput
        type="button"
        @click.prevent="changeEmail"
        :disabled="hasErrors"
        value="E-Mail ändern"
      />
    </FormulateForm>
    <div class="text-center mt-5 text-sm">
      <router-link to="/userprofile" class="hover:underline"
        >zurück zur Profilseite</router-link
      >
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase.js";
import { mapActions, mapState } from "vuex";
import firebase from "firebase/app";
import TheTitle from "@/components/TheTitle.vue";

export default {
  components: {
    TheTitle
  },
  data() {
    return {
      currentEmail: "",
      newEmail: "",
      password: "",
      successMessage:
        "Deine E-Mail-Adresse wurde erfolgreich geändert. Du wirst nun abgemeldet und zur Anmeldeseite weitergeleitet."
    };
  },
  computed: {
    ...mapState(["error"])
  },
  methods: {
    ...mapActions(["errorMessageFromFirebase", "signOutAction"]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
    },
    successNotification() {
      this.$emit("").$parent.$refs.notification.isShown = true;
      this.$emit("").$parent.$refs.notification.show = false;
      this.$emit("").$parent.$refs.notification.note = this.successMessage;
      this.$emit("").$parent.$refs.notification.type = "success";
      this.$emit("").$parent.$refs.notification.showNotification();
      setTimeout(() => {
        this.$emit("").$parent.$refs.notification.isShown = false;
        this.$router.replace("/signin");
      }, 4000);
    },

    changeEmail() {
      let credential = firebase.auth.EmailAuthProvider.credential(
        this.currentEmail,
        this.password
      );
      auth.currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          this.successNotification();
          auth.currentUser.updateEmail(this.newEmail).then(() => {
            this.signOutAction();
          });
        })
        .catch(error => {
          this.errorMessageFromFirebase(error);
          this.errorNotification();
        });
    }
  }
};
</script>

<style></style>
