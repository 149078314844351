<template>
  <div
    id="sign-in"
    class="m-0 m-auto flex flex-col content-center sm:w-3/5 md:w-1/2 lg:w-1/3"
  >
    <Quote />
    <FormulateForm
      #default="{hasErrors}"
      class="bg-gray-50 rounded px-5 pt-5 pb-1"
    >
      <FormulateInput
        type="text"
        v-model="email"
        placeholder="E-Mail"
        @keypress.enter="signIn"
        validation="^required|email"
        validation-name="E-Mail"
      />
      <FormulateInput
        type="password"
        v-model="password"
        placeholder="Passwort"
        @keypress.enter="signIn"
        validation="required"
        validation-name="Passwort"
      />
      <FormulateInput
        type="button"
        value="Anmelden"
        :disabled="hasErrors"
        @click.prevent="signIn"
      />
    </FormulateForm>
    <div class="text-center text-sm my-10">
      <router-link to="/resetpassword" class="hover:underline"
        >Passwort vergessen?</router-link
      >
      <br />
      <br />
      Du bist noch nicht registriert?
      <router-link to="/signup" class="font-semibold text-pangea-yellow">
        Registrieren</router-link
      >
    </div>
  </div>
</template>

<script>
import Quote from "@/components/Quote.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "SignIn",
  components: {
    Quote
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapState(["error"])
  },
  methods: {
    ...mapActions([
      "errorMessageFromFirebase",
      "signInAction",
      "signOutAction"
    ]),
    errorNotification() {
      this.$emit("").$parent.$refs.notification.note = this.error;
      this.$emit("").$parent.$refs.notification.show = true;
      this.$emit("").$parent.$refs.notification.type = "error";
      this.$emit("").$parent.$refs.notification.showNotification();
    },
    signIn() {
      try {
        this.signInAction({ email: this.email, password: this.password }).then(
          () => {
            console.log("signInAction was triggered!");
            if (this.error) {
              this.errorNotification();
              this.errorMessageFromFirebase({});
            } else {
              this.$router.replace("/");
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
